import { getApiAsApp } from '@board/basics/api';
import { redirect } from '@board/shared/web';

export const logout = async () => {
  try {
    await getApiAsApp().logout();
  } catch (e) {
    // do nothing
  }
  // logout conflicts with removed user
  // manually clean the auth state
  try {
    getApiAsApp().authentication.removeAccessToken();
    await getApiAsApp().authentication.reset();
  } catch (e) {
    // do nothing
  }
  redirect('/login');
};

export const reauthOrLogout = async () => {
  try {
    await getApiAsApp().reAuthenticate(true);
  } catch (e) {
    await logout();
  }
};
