import authentication from '@feathersjs/authentication-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';

// eslint-disable-next-line import/no-unresolved
import { API_URL, authStorageKey } from '@board/shared/api';
import { noop } from '@board/shared/utils';
import { isCypressComponentTest } from '@board/shared/web';

const socket = io(API_URL, {
  transports: ['websocket', 'polling'],
  forceNew: true,
  reconnection: true,
  upgrade: true,
  rememberUpgrade: true,
});

const app = feathers();

app.configure(
  socketio(socket, {
    timeout: 10000,
  }),
);
app.configure(
  authentication({
    storageKey: authStorageKey,
  }),
);

if (process.env.NODE_ENV === 'development') {
  (window as any).app = app;
  if (isCypressComponentTest) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    app.service = (name: string) => {
      console.warn(`Service ${name} is called`);
      return {
        create: noop,
        patch: noop,
        find: noop,
        remove: noop,
        on: noop,
      };
    };
  }
}

export const inServerApi = app;
