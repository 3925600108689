import { useCallback, useState } from 'react';

import type { FeathersError } from '@feathersjs/errors';

import { onRequestError } from '@board/basics/alert';
import { getApiAsApp } from '@board/basics/api';
import { usePrevious } from '@board/hooks/usePrevious';
import type { ILoader } from '@board/shared/redux/loader';

import type { serviceNames } from '@board/shared/serviceNames';

type onEvent<P> = (
  payload: P,
  params?: {
    [key: string]: any;
  },
) => void;

export const useFeathersCreate = <Payload, Response = unknown>(
  serviceName: serviceNames,
): [onEvent<Payload>, ILoader, Response | null] => {
  const [pending, setPending] = useState(false);
  const [data, setData] = useState<Response | null>(null);
  const service = getApiAsApp().service(serviceName);
  const prevPending = usePrevious(pending);

  const onEvent = useCallback(
    (
      payload: Payload,
      params?: {
        [key: string]: any;
      },
    ) => {
      setPending(true);
      setData(null);
      service
        .create(payload, params)
        .then((response: Response) => {
          setData(response);
          setPending(false);
        })
        .catch((e: FeathersError) => {
          setPending(false);
          onRequestError(e);
        });
    },
    [],
  );
  const progress: ILoader = {
    pending,
    success: Boolean(prevPending) && !pending,
    error: false,
  };
  return [onEvent, progress, data];
};
