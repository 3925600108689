import type { DraggableLocation } from 'react-beautiful-dnd';

import type { IBoard, IItem, IWidgetFull, ItemAny } from '../model';

export enum DroppableTypes {
  boards = 'boards',
  widgets = 'widgets',
  items = 'items',
  comboWidget = 'combo',
}

export interface DnDBase<G extends IItem<unknown> = ItemAny> {
  draggableId: string;
  source: DraggableLocation;
  destination: DraggableLocation & {
    patchData?: G['data'];
  };
}

export interface DnDResult<G extends IItem<unknown> = ItemAny>
  extends DnDBase<G> {
  type: DroppableTypes;
  mode?: 'FLUID' | 'SNAP';
}

interface ItemsMoveBasicRes {
  dnd: DnDResult;
}

interface ItemsMoveBoardsRes extends ItemsMoveBasicRes {
  type: DroppableTypes.boards;
  boards: IBoard[];
}

interface ItemsMoveItemsRes extends ItemsMoveBasicRes {
  type: DroppableTypes.widgets;
  widgets: IWidgetFull[];
  result: Record<string, IItem<unknown>[] | undefined>;
}
export type ItemsMoveRes = ItemsMoveBoardsRes | ItemsMoveItemsRes;
