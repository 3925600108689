/* eslint-disable no-param-reassign */
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';

import type { ApiContextValue } from '@board/basics/useApiContext';
import { DroppableTypes, type ItemsMoveRes } from '@board/shared/dnd';
import { featherEventEmitters } from '@board/shared/featherTypes';
import type { ItemAny } from '@board/shared/model';
import { serviceNames } from '@board/shared/serviceNames';
import { arrayHelper, shouldBeArr, shouldBeNum } from '@board/shared/utils';

import { devtools, immerMiddleware } from '../_shared_/zustand';

type StateNext = {
  items: Record<
    number,
    {
      items: ItemAny[];
      ready: boolean;
      id: number;
    }
  >;
  init(id: number, items: ItemAny[]): void;
  setItems(id: number, items: ItemAny[], sort?: boolean): void;
  concat(id: number, items: ItemAny[]): void;
  patch(id: number, item: ItemAny): void;
  remove(id: number, item: ItemAny): void;
};

// updating items in store causing proxy use
// just use mutable function to update them
const setItemsInt = (
  state: StateNext,
  id: number,
  items: ItemAny[],
  sort = true,
) => {
  if (sort) {
    state.items[id].items = arrayHelper.sortByPosition(items);
  } else {
    // hack for immediate reorder
    state.items[id].items = items;
  }
};

/**
 * Unfortunate solution for adding new tasks that return the whole list of items
 */

const concatAndFilter = (items: ItemAny[], nextItems: ItemAny[]) => {
  const ids = new Set<number>();
  nextItems.forEach(i => {
    ids.add(i.id);
  });
  return items.filter(i => !ids.has(i.id)).concat(nextItems);
};

export const useItemStore = create<StateNext>()(
  devtools(
    immerMiddleware(set => ({
      items: {},
      init: (id, items) =>
        set(state => {
          state.items[id] = {
            items: [],
            ready: true,
            id,
          };
          setItemsInt(state, id, items);
        }),
      setItems: (id, items, sort) =>
        set(state => {
          setItemsInt(state, id, items, sort);
        }),
      concat: (id, newItems) =>
        set(state =>
          setItemsInt(
            state,
            id,
            concatAndFilter(itemSelector.items(id)(state), newItems),
          ),
        ),
      remove: (id, item) =>
        set(state => {
          setItemsInt(
            state,
            id,
            itemSelector
              .items(id)(state)
              .filter(i => i.id !== item.id),
          );
        }),
      patch: (id, item) =>
        set(state => {
          setItemsInt(
            state,
            id,
            itemSelector
              .items(id)(state)
              .map(i => (i.id === item.id ? item : i)),
          );
        }),
    })),
    'items',
  ),
);

export const itemSelector = {
  isReady: (id: number) => (state: StateNext) =>
    Boolean(state.items[id]?.ready),
  items:
    <G extends ItemAny>(id: number) =>
    (state: StateNext) =>
      state.items[id].items as G[],
  storeItems: <G extends ItemAny>(id: number) =>
    itemSelector.items<G>(id)(useItemStore.getState()),
};

export const itemAction = {
  storeInit: (...args: Parameters<StateNext['init']>) =>
    unstable_batchedUpdates(() => {
      useItemStore.getState().init(...args);
    }),
  storeSet: (...args: Parameters<StateNext['setItems']>) =>
    unstable_batchedUpdates(() => {
      useItemStore.getState().setItems(...args);
    }),
  concat: (...args: Parameters<StateNext['concat']>) =>
    unstable_batchedUpdates(() => {
      useItemStore.getState().concat(...args);
    }),
  patch: (...args: Parameters<StateNext['patch']>) =>
    unstable_batchedUpdates(() => {
      useItemStore.getState().patch(...args);
    }),
  batchRemove: (items: ItemAny[]) =>
    unstable_batchedUpdates(() => {
      items.forEach(item => {
        useItemStore.getState().remove(item.widgetId, item);
      });
    }),
};

// -----LISTENERS----//
/**
 * Listeners migrated from hook-based approach to avoid duplication of subscription
 * for example, opened board with the same widget in floating widget model
 */

const isWidgetInStore = (id: number) =>
  Boolean(useItemStore.getState().items[id]);
export const itemsStoreSetupListeners = (app: ApiContextValue) => {
  const service = app.service(serviceNames.items);

  service.on(featherEventEmitters.create, ({ widgetId, items }) => {
    if (isWidgetInStore(widgetId)) {
      itemAction.concat(widgetId, items);
    }
  });
  service.on(featherEventEmitters.patch, payload => {
    if (isWidgetInStore(payload.widgetId)) {
      itemAction.patch(payload.widgetId, payload);
    }
  });

  service.on(featherEventEmitters.remove, _payload => {
    const payload = shouldBeArr(_payload);
    if (isWidgetInStore(payload[0].widgetId)) {
      itemAction.batchRemove(payload);
    }
  });
  const dndService = app.service(serviceNames.itemsMove);

  dndService.on(featherEventEmitters.create, (payload: ItemsMoveRes) => {
    if (payload.type === DroppableTypes.widgets) {
      Object.entries(payload.result).forEach(([_id, items]) => {
        const id = shouldBeNum(_id);
        if (items && isWidgetInStore(id)) {
          itemAction.storeSet(id, items);
        }
      });
    }
  });
};
