import React from 'react';

import { LoadableComponent } from '@board/ui/LoadableComponent';

const Component = React.lazy(() => import('./PublicRoutes'));
export const PublicRoutes = () => (
  <LoadableComponent>
    <Component />
  </LoadableComponent>
);
