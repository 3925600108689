/* eslint-disable no-param-reassign */
import type { StateCreator, StoreMutatorIdentifier } from 'zustand';
// Copy types to avoid import type { Config } from '@redux-devtools/extension'
// https://github.com/pmndrs/zustand/issues/1205
type Action<T = any> = {
  type: T;
};
type ActionCreator<A, P extends any[] = any[]> = (...args: P) => A;
type EnhancerOptions = {
  name?: string;
  actionCreators?:
    | ActionCreator<any>[]
    | {
        [key: string]: ActionCreator<any>;
      };
  latency?: number;
  maxAge?: number;
  serialize?:
    | boolean
    | {
        options?:
          | undefined
          | boolean
          | {
              date?: true;
              regex?: true;
              undefined?: true;
              error?: true;
              symbol?: true;
              map?: true;
              set?: true;
              function?: true | ((fn: (...args: any[]) => any) => string);
            };
        replacer?: (key: string, value: unknown) => any;
        reviver?: (key: string, value: unknown) => any;
        immutable?: any;
        refs?: any;
      };
  actionSanitizer?: <A extends Action>(action: A, id: number) => A;
  stateSanitizer?: <S>(state: S, index: number) => S;
  actionsBlacklist?: string | string[];
  actionsWhitelist?: string | string[];
  actionsDenylist?: string | string[];
  actionsAllowlist?: string | string[];
  predicate?: <S, A extends Action>(state: S, action: A) => boolean;
  shouldRecordChanges?: boolean;
  pauseActionType?: string;
  autoPause?: boolean;
  shouldStartLocked?: boolean;
  shouldHotReload?: boolean;
  shouldCatchErrors?: boolean;
  features?: {
    pause?: boolean;
    lock?: boolean;
    persist?: boolean;
    export?: boolean | 'custom';
    import?: boolean | 'custom';
    jump?: boolean;
    skip?: boolean;
    reorder?: boolean;
    dispatch?: boolean;
    test?: boolean;
  };
  trace?: boolean | (<A extends Action>(action: A) => string);
  traceLimit?: number;
};
type Config = EnhancerOptions & {
  type?: string;
};

declare module 'zustand/vanilla' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface StoreMutators<S, A> {
    'zustand/devtools': WithDevtools<S>;
  }
}

// FIXME https://github.com/reduxjs/redux-devtools/issues/1097
type Message = {
  type: string;
  payload?: any;
  state?: any;
};

type Cast<T, U> = T extends U ? T : U;
type Write<T, U> = Omit<T, keyof U> & U;
type TakeTwo<T> = T extends { length: 0 }
  ? [undefined, undefined]
  : T extends { length: 1 }
    ? [...a0: Cast<T, unknown[]>, a1: undefined]
    : T extends { length: 0 | 1 }
      ? [...a0: Cast<T, unknown[]>, a1: undefined]
      : T extends { length: 2 }
        ? T
        : T extends { length: 1 | 2 }
          ? T
          : T extends { length: 0 | 1 | 2 }
            ? T
            : T extends [infer A0, infer A1, ...unknown[]]
              ? [A0, A1]
              : T extends [infer A0, (infer A1)?, ...unknown[]]
                ? [A0, A1?]
                : T extends [(infer A0)?, (infer A1)?, ...unknown[]]
                  ? [A0?, A1?]
                  : never;

type WithDevtools<S> = Write<S, StoreDevtools<S>>;

type StoreDevtools<S> = S extends {
  setState: (...a: infer Sa) => infer Sr;
}
  ? {
      setState<A extends string | { type: unknown }>(
        ...a: [...a: TakeTwo<Sa>, action?: A]
      ): Sr;
    }
  : never;

type DevtoolsOptions = string;

type Devtools = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
  initializer: StateCreator<T, [...Mps, ['zustand/devtools', never]], Mcs>,
  devtoolsOptions?: DevtoolsOptions,
) => StateCreator<T, Mps, [['zustand/devtools', never], ...Mcs]>;

declare module 'zustand/vanilla' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface StoreMutators<S, A> {
    'zustand/devtools': WithDevtools<S>;
  }
}

type DevtoolsImpl = <T>(
  storeInitializer: StateCreator<T, [], []>,
  devtoolsOptions?: DevtoolsOptions,
) => StateCreator<T, [], []>;

const devtoolsImpl: DevtoolsImpl =
  (initializer, prefix) => (set, get, store) => {
    if (process.env.NODE_ENV === 'development') {
      store.setState = (state, replace) => {
        const r = set(state, replace);
        import('./devtoolsStore').then(({ store }) => {
          store.dispatch({
            type: prefix,
            payload: get(),
          });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.store = store.getState();
        });

        return r;
      };
    }
    return initializer(store.setState, get, store);
  };
export const devtools = devtoolsImpl as unknown as Devtools;
