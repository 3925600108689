/* eslint-disable no-param-reassign */
import { unstable_batchedUpdates } from 'react-dom';

import { reauthOrLogout } from '@board/basics/api';
import { createStore, immerMiddleware } from '@board/dp/store/_shared_/zustand';

import { type Alert, AlertType } from './types';
import { chooseErrorMessage, isHandledError, isUnAuthed } from './utils';

type AlertStore = {
  alert: Alert | null;
  type: AlertType;
  set(alert: Alert, type: AlertType): void;
  success(alert: Alert): void;
  error(alert: Alert): void;
  info(alert: Alert): void;
  hide(): void;
};

export const useAlertStore = createStore<AlertStore>()(
  immerMiddleware(set => ({
    alert: null,
    type: AlertType.Success,
    set: (alert, type) => {
      set(state => {
        state.alert = alert;
        state.type = type;
      });
    },
    success: alert => set(s => s.set(alert, AlertType.Success)),
    error: alert =>
      set(s => {
        console.warn(alert);
        return s.set(alert, AlertType.Error);
      }),
    info: alert => set(s => s.set(alert, AlertType.Info)),
    hide: () => set({ alert: null }),
  })),
);

export const alertAction = {
  set: (alert: Alert, type: AlertType) =>
    unstable_batchedUpdates(() => {
      useAlertStore.getState().set(alert, type);
    }),
  success: (alert: Alert) =>
    unstable_batchedUpdates(() => {
      useAlertStore.getState().set(alert, AlertType.Success);
    }),
};

export const onRequestError = (e: unknown) => {
  if (isHandledError(e)) {
    if (isUnAuthed(e)) {
      reauthOrLogout();
    } else {
      const alert = chooseErrorMessage(e);

      if (alert) {
        alertAction.set(alert, AlertType.Error);
      }
    }
  }
};
