export const getClearBit = (url: string | undefined) => {
  if (url) {
    try {
      return `https://logo.clearbit.com/${
        new URL(url).origin
      }?size=64&format=png`;
    } catch (e) {
      // do nothing
    }
  }
  return undefined;
};

export const getFaviconKit = (url: string | undefined) => {
  if (!url) {
    return undefined;
  }
  return `https://api.faviconkit.com/${new URL(url).origin}/144`;
};
