import React, { useMemo } from 'react';

import cx from 'clsx';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { BodyCenter } from '../BodyCenter';

export enum SpinnerColors {
  Primary,
  Secondary,
  White,
}

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    display: 'inline-block',
  },
  [`color${SpinnerColors.Primary}`]: {
    backgroundColor: theme.palette.primary.main,
  },
  /* Styles applied to the root element if `color="secondary"`. */
  [`color${SpinnerColors.Secondary}`]: {
    backgroundColor: theme.palette.secondary.main,
  },
  [`color${SpinnerColors.White}`]: {
    backgroundColor: theme.palette.common.white,
  },
  bounce: {
    opacity: 0.4,
    borderRadius: '100%',
    display: 'inline-block',
    animation: '$bounce 1.4s infinite ease-in-out both',
  },
  bounce1: {
    animationDelay: '-0.32s',
  },
  bounce2: {
    animationDelay: '-0.16s',
  },
  '@keyframes bounce': {
    '0%, 80%, 100%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1.0)',
    },
  },
}));

interface SpinnerProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> {
  color?: SpinnerColors;
  size?: number;
}

export const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(
  ({ size = 50, color = SpinnerColors.Primary, ...rest }, ref) => {
    const classes = useStyles();
    const third = Math.floor(size / 3);
    const style = useMemo(() => {
      return {
        width: third,
        height: third,
      };
    }, [third]);
    return (
      <div
        className={cx(classes.root)}
        {...rest}
        data-test="spinner"
        ref={ref}
        style={{ width: size }}
      >
        <div
          className={cx(
            classes.bounce,
            classes.bounce1,
            classes[`color${color}`],
          )}
          style={style}
        />
        <div
          className={cx(
            classes.bounce,
            classes.bounce2,
            classes[`color${color}`],
          )}
          style={style}
        />
        <div
          className={cx(classes.bounce, classes[`color${color}`])}
          style={style}
        />
      </div>
    );
  },
);

export const SpinnerCenter = React.forwardRef<HTMLDivElement, SpinnerProps>(
  (props, ref) => (
    <Box textAlign="center">
      <Spinner ref={ref} {...props} />
    </Box>
  ),
);

export const SpinnerGridCenter = React.forwardRef<HTMLDivElement, SpinnerProps>(
  (props, ref) => {
    return (
      <BodyCenter>
        <Spinner {...props} />
      </BodyCenter>
    );
  },
);
