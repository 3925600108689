import { type IBoard, type IBoardWithWidgets, boardRelation } from './board';
import type { IUser, userKeys } from './user';

export enum shareKeys {
  id = 'id',
  boardId = 'boardId',
  email = 'email',
  userId = 'userId',
}
export enum shareRelation {
  board = 'board',
  user = 'user',
}

export interface IShareDraft {
  boardId: number;
  email: string;
}

export interface IShare extends IShareDraft {
  id: number;
  userId: number;
}

export interface IShareFull extends IShare {
  board: IBoard;
  user: Pick<IUser, userKeys.id | userKeys.name | userKeys.email>;
}

export interface IShareFullBoard extends IShareFull {
  board: IBoardWithWidgets;
}

export const shareEagers = {
  all: `[${shareRelation.board}, ${shareRelation.user}]`,
  fullBoard: `[${shareRelation.board}.${boardRelation.widgets}, ${shareRelation.user}]`,
};
