/* eslint-disable no-param-reassign */
import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';

import type { ApiContextValue } from '@board/basics/useApiContext';
import {
  FeatherEvents,
  featherEventEmitters,
} from '@board/shared/featherTypes';
import { type IUser, SubmitKeys } from '@board/shared/model';
import { serviceNames } from '@board/shared/serviceNames';

import { devtools, immerMiddleware } from '../_shared_/zustand';

type State = {
  user: IUser | null;
  inPrivate: boolean;
  setUser(user: IUser): void;
  updateUser(user: IUser): void;
};

export const useUserStore = create<State>()(
  devtools(
    immerMiddleware(set => ({
      user: null,
      inPrivate: false,
      setUser: user =>
        set(state => {
          state.user = user;
        }),
      updateUser: user =>
        set(state => {
          state.user = Object.assign(state.user || {}, user);
        }),
    })),
    'user',
  ),
);

export const userAction = {
  set: (user: IUser) => {
    unstable_batchedUpdates(() => {
      useUserStore.getState().setUser(user);
    });
  },
};

export const userSelector = {
  get: ({ user }: State) => {
    if (!user) {
      throw new Error('Not autorized');
    }
    return user;
  },
  getProp:
    <T extends keyof IUser>(key: T) =>
    (state: State): IUser[T] =>
      userSelector.get(state)[key],

  config:
    <T extends keyof IUser['config']>(key: T) =>
    (state: State): IUser['config'][T] =>
      userSelector.get(state).config[key],
  id: (state: State) => userSelector.getProp('id')(state),
  submitKey: (state: State) =>
    userSelector.getProp('submitKey')(state) || SubmitKeys.enter,
};

export const userStoreSetupListener = (app: ApiContextValue) => {
  const userService = app.service(serviceNames.users);
  const listener = (user: IUser) => userAction.set(user);
  userService.on(featherEventEmitters[FeatherEvents.patch], listener);
};
