import '@icon-park/react/styles/index.css';

import React, { useMemo } from 'react';

import {
  DEFAULT_ICON_CONFIGS,
  IconProvider,
} from '@icon-park/react/es/runtime';
import { Router } from 'react-router-dom';

import { StyledEngineProvider, ThemeProvider } from '@mui/material';

import {
  uiSelector,
  useUiSettingStore,
} from '@board/basics/helpers/uiSettingStore';
import { useDarkMode } from '@board/basics/helpers/useDarkMode';
import { history } from '@board/basics/route';
import { createCustomTheme, createDefaultTheme } from '@board/shared/ui/theme';

import { CssBoardBaseline } from '../components/CssBoardBaseline';

import { NavigateUser } from './NavigateUser';

const iconsConfig = {
  ...DEFAULT_ICON_CONFIGS,
  size: 24,
};

const AppContainer = () => {
  const isDark = useDarkMode(state => state.isDark);
  const primaryColor = useUiSettingStore(uiSelector.primaryColor);
  const theme = useMemo(() => {
    if (primaryColor) {
      return createCustomTheme(primaryColor, false);
    }
    return createDefaultTheme(isDark);
  }, [isDark, primaryColor]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <IconProvider value={iconsConfig}>
          <Router history={history}>
            <NavigateUser />
            <CssBoardBaseline />
          </Router>
        </IconProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppContainer;
