/* eslint-disable no-underscore-dangle */
import { platform } from './ackee-platform';
import { noop } from './fn';

// const platform: Record<string, any> = {};

const platformTraker = platform as {
  product: string;
  manufacturer: string;
  os: {
    family: string;
    version: string;
  };
  name: string;
  version: string;
};
export interface TrackingOptions {
  /**
   * Defaults to `true`
   */
  ignoreLocalhost?: boolean | undefined;
  /**
   * Defaults to `false`
   */
  detailed?: boolean | undefined;
  /**
   * Defaults to `true`
   */
  ignoreOwnVisits?: boolean | undefined;
}

export interface AckeeTrackingReturn {
  stop: () => void;
}

export interface ActionAttributes {
  /**
   * Key that will be used to group similar actions in the Ackee UI.
   */
  key: string;
  /**
   * Positive float value that is added to all other numerical values of the key.
   */
  value?: number | undefined;
}

export interface DefaultData {
  siteLocation: string;
  siteReferrer: string;
}

// Based on https://github.com/bestiejs/platform.js/blob/master/platform.js
export interface DetailedData {
  siteLanguage: string;
  screenWidth: number;
  screenHeight: number;
  screenColorDepth: number;
  deviceName: string | null;
  deviceManufacturer: string | null;
  osName: string | null;
  osVersion: string | null;
  browserName: string | null;
  browserVersion: string | null;
  browserWidth: number;
  browserHeight: number;
}

/**
 * Validates options and sets defaults for undefined properties.
 * @param {?Object} options
 * @returns {Object} options - Validated options.
 */
const validate = function (options: TrackingOptions = {}) {
  // Create new object to avoid changes by reference
  const _options: TrackingOptions = {};

  // Defaults to false
  _options.detailed = options.detailed === true;

  // Defaults to true
  _options.ignoreLocalhost = options.ignoreLocalhost !== false;

  // Defaults to true
  _options.ignoreOwnVisits = options.ignoreOwnVisits !== false;

  return _options;
};

/**
 * Determines if a host is a localhost.
 * @param {String} hostname - Hostname that should be tested.
 * @returns {Boolean} isLocalhost
 */
const isLocalhost = function (hostname: string) {
  return (
    hostname === '' ||
    hostname === 'localhost' ||
    hostname === '127.0.0.1' ||
    hostname === '::1'
  );
};

/**
 * Determines if user agent is a bot. Approach is to get most bots, assuming other bots don't run JS.
 * Source: https://stackoverflow.com/questions/20084513/detect-search-crawlers-via-javascript/20084661
 * @param {String} userAgent - User agent that should be tested.
 * @returns {Boolean} isBot
 */
const isBot = function (userAgent: string) {
  return /bot|crawler|spider|crawling/i.test(userAgent);
};

/**
 * Checks if an id is a fake id. This is the case when Ackee ignores you because of the `ackee_ignore` cookie.
 * @param {String} id - Id that should be tested.
 * @returns {Boolean} isFakeId
 */
const isFakeId = function (id: string) {
  return id === '88888888-8888-8888-8888-888888888888';
};

/**
 * Checks if the website is in background (e.g. user has minimzed or switched tabs).
 * @returns {boolean}
 */
const isInBackground = function () {
  return document.visibilityState === 'hidden';
};

/**
 * Get the optional source parameter.
 * @returns {String} source
 */
const source = function () {
  const source = (window.location.search.split(`source=`)[1] || '').split(
    '&',
  )[0];

  return source === '' ? undefined : source;
};

/**
 * Gathers all platform-, screen- and user-related information.
 * @param {Boolean} detailed - Include personal data.
 * @returns {Object} attributes - User-related information.
 */
export const attributes = function (detailed = false): DefaultData {
  const defaultData = {
    siteLocation: window.location.href,
    siteReferrer: document.referrer,
    source: source(),
  };

  const { screen } = window;
  const detailedData = {
    siteLanguage: navigator.language.substr(0, 2),
    screenWidth: screen.width,
    screenHeight: screen.height,
    screenColorDepth: screen.colorDepth,
    deviceName: platformTraker.product,
    deviceManufacturer: platformTraker.manufacturer,
    osName: platformTraker.os?.family,
    osVersion: platformTraker.os?.version,
    browserName: platformTraker.name,
    browserVersion: platformTraker.version,
    browserWidth: window.outerWidth,
    browserHeight: window.outerHeight,
  };

  return {
    ...defaultData,
    ...(detailed === true ? detailedData : {}),
  };
};

/**
 * Creates an object with a query and variables property to create a record on the server.
 * @param {String} domainId - Id of the domain.
 * @param {Object} input - Data that should be transferred to the server.
 * @returns {Object} Create record body.
 */
const createRecordBody = function (domainId: string, input: DefaultData) {
  return {
    query: `
			mutation createRecord($domainId: ID!, $input: CreateRecordInput!) {
				createRecord(domainId: $domainId, input: $input) {
					payload {
						id
					}
				}
			}
		`,
    variables: {
      domainId,
      input,
    },
  };
};

/**
 * Creates an object with a query and variables property to update a record on the server.
 * @param {String} recordId - Id of the record.
 * @returns {Object} Update record body.
 */
const updateRecordBody = function (recordId: string) {
  return {
    query: `
			mutation updateRecord($recordId: ID!) {
				updateRecord(id: $recordId) {
					success
				}
			}
		`,
    variables: {
      recordId,
    },
  };
};

/**
 * Creates an object with a query and variables property to create an action on the server.
 * @param {String} eventId - Id of the event.
 * @param {Object} input - Data that should be transferred to the server.
 * @returns {Object} Create action body.
 */
const createActionBody = (eventId: string, input: ActionAttributes) => ({
  query: `
			mutation createAction($eventId: ID!, $input: CreateActionInput!) {
				createAction(eventId: $eventId, input: $input) {
					payload {
						id
					}
				}
			}
		`,
  variables: {
    eventId,
    input,
  },
});

/**
 * Creates an object with a query and variables property to update an action on the server.
 * @param {String} actionId - Id of the action.
 * @param {Object} input - Data that should be transferred to the server.
 * @returns {Object} Update action body.
 */
const updateActionBody = (actionId: string, input: ActionAttributes) => ({
  query: `
			mutation updateAction($actionId: ID!, $input: UpdateActionInput!) {
				updateAction(id: $actionId, input: $input) {
					success
				}
			}
		`,
  variables: {
    actionId,
    input,
  },
});

/**
 * Construct URL to the GraphQL endpoint of Ackee.
 * @param {String} server - URL of the Ackee server.
 * @returns {String} endpoint - URL to the GraphQL endpoint of the Ackee server.
 */
const endpoint = (server: string) => {
  const hasTrailingSlash = server.substr(-1) === '/';

  return `${server + (hasTrailingSlash ? '' : '/')}api`;
};

/**
 * Sends a request to a specified URL.
 * Won't catch all errors as some are already logged by the browser.
 * In this case the callback won't fire.
 * @param {String} url - URL to the GraphQL endpoint of the Ackee server.
 * @param {Object} body - JSON which will be send to the server.
 * @param {Object} options
 * @param {?Function} next - The callback that handles the response. Receives the following properties: json.
 */
const send = function (
  url: string,
  body:
    | ReturnType<typeof createRecordBody>
    | ReturnType<typeof updateRecordBody>
    | ReturnType<typeof createActionBody>
    | ReturnType<typeof updateActionBody>,
  options: TrackingOptions,
  next?: (json: Record<string, any>) => void,
) {
  const xhr = new XMLHttpRequest();

  xhr.open('POST', url);

  xhr.onload = () => {
    if (xhr.status !== 200) {
      throw new Error('Server returned with an unhandled status');
    }

    let json = null;

    try {
      json = JSON.parse(xhr.responseText);
    } catch (e) {
      throw new Error('Failed to parse response from server');
    }

    if (json.errors != null) {
      throw new Error(json.errors[0].message);
    }

    if (typeof next === 'function') {
      return next(json);
    }
    return undefined;
  };

  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  xhr.withCredentials = Boolean(options.ignoreOwnVisits);

  xhr.send(JSON.stringify(body));
};

/**
 * Creates a new instance.
 * @param {String} server - URL of the Ackee server.
 * @param {?Object} options
 * @returns {Object} instance
 */
export const create = (server: string, _options: TrackingOptions) => {
  const options = validate(_options);
  const url = endpoint(server);

  // Fake instance when Ackee ignores you
  const fakeInstance = {
    record: () => ({ stop: noop }),
    updateRecord: () => ({ stop: noop }),
    action: noop,
    updateAction: noop,
  };

  if (
    options.ignoreLocalhost === true &&
    isLocalhost(window.location.hostname)
  ) {
    console.warn('Ackee ignores you because you are on localhost');
    return fakeInstance;
  }

  if (isBot(navigator.userAgent)) {
    console.warn('Ackee ignores you because you are a bot');
    return fakeInstance;
  }

  // Creates a new record on the server and updates the record
  // very x seconds to track the duration of the visit. Tries to use
  // the default attributes when there're no custom attributes defined.
  const _record = (
    domainId: string,
    attrs: DefaultData = attributes(options.detailed),
  ) => {
    // Function to stop updating the record
    let isStopped = false;
    const stop = () => {
      isStopped = true;
    };

    send(url, createRecordBody(domainId, attrs), options, json => {
      const recordId = json.data.createRecord.payload.id;

      if (isFakeId(recordId)) {
        console.warn('Ackee ignores you because this is your own site');
        return;
      }

      const interval = setInterval(() => {
        if (isStopped) {
          clearInterval(interval);
          return;
        }

        if (isInBackground()) return;

        send(url, updateRecordBody(recordId), options);
      }, 15000);
    });

    return { stop };
  };

  // Updates a record very x seconds to track the duration of the visit
  const _updateRecord = (recordId: string) => {
    // Function to stop updating the record
    let isStopped = false;
    const stop = () => {
      isStopped = true;
    };

    if (isFakeId(recordId)) {
      console.warn('Ackee ignores you because this is your own site');
      return { stop };
    }

    const interval = setInterval(() => {
      if (isStopped) {
        clearInterval(interval);
        return;
      }

      if (isInBackground()) return;

      send(url, updateRecordBody(recordId), options);
    }, 15000);

    return { stop };
  };

  // Creates a new action on the server
  const _action = (eventId: string, attrs: ActionAttributes) => {
    send(url, createActionBody(eventId, attrs), options, json => {
      const actionId = json.data.createAction.payload.id;

      if (isFakeId(actionId)) {
        console.warn('Ackee ignores you because this is your own site');
      }
    });
  };

  // Updates an action
  const _updateAction = (actionId: string, attrs: ActionAttributes) => {
    if (isFakeId(actionId)) {
      console.warn('Ackee ignores you because this is your own site');
      return;
    }

    send(url, updateActionBody(actionId, attrs), options);
  };

  // Return the real instance
  return {
    record: _record,
    updateRecord: _updateRecord,
    action: _action,
    updateAction: _updateAction,
  };
};
