import isEqual from 'react-fast-compare';

export enum memoKey {
  archivedBoards,
  boardList,
  boardIdList,
  ownedBoards,
  favoriteWidgets,
}

function defaultEqualityCheck(a: any, b: any) {
  return a === b;
}

function areArgumentsShallowlyEqual(prev: any[], next: any[]) {
  if (prev === undefined || next === undefined || prev.length !== next.length) {
    return false;
  }

  // Do this in a for loop (and not a `forEach` or an `every`) so we can determine equality as fast as possible.
  const { length } = prev;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    if (!defaultEqualityCheck(prev[i], next[i])) {
      return false;
    }
  }

  return true;
}

type MemoAll = {
  [key: string]: {
    lastArgs?: any;
    lastResult?: any;
  };
};

const memoAll: MemoAll = {};

const ownMemoize = <
  Id extends string | memoKey,
  List extends Array<number | string>,
  T,
  Res,
>(
  listId: Id,
  list: List,
  tree: Record<string, T>,
  resultFn: (list: List, tree: Record<string, T>, listId: Id) => Res,
): Res => {
  if (!memoAll[listId]) {
    memoAll[listId] = {};
  }

  const memo = memoAll[listId];

  if (!areArgumentsShallowlyEqual(memo.lastArgs, [list, tree])) {
    const res = resultFn(list, tree, listId);
    if (!isEqual(res, memo.lastResult)) {
      // console.log('Recompute', listId, Diff(memo.lastArgs, [list, tree]));
      memo.lastArgs = [list, tree];
      memo.lastResult = resultFn(list, tree, listId);
    }
  }

  return memo.lastResult;
};

export const simpleMemo = <G>(id: string | memoKey, data: G[]): G[] => {
  if (!memoAll[id]) {
    memoAll[id] = {};
  }
  const memo = memoAll[id];
  if (!isEqual(data, memo.lastResult)) {
    memo.lastResult = data;
  }

  return memo.lastResult;
};

export const anyMemo = <G>(id: string, data: G): G => {
  if (!memoAll[id]) {
    memoAll[id] = {};
  }
  const memo = memoAll[id];
  if (!isEqual(data, memo.lastResult)) {
    memo.lastResult = data;
  }

  return memo.lastResult;
};

type KeyId = number | string;

type Tree = {
  [key: string]: any;
  [key: number]: any;
};

interface Options {
  id: string | memoKey;
  list: KeyId[];
}

interface FnOptions<G, T> extends Options {
  fn: (res: G[]) => T;
}

const getElements = <G>(
  list: Options['list'],
  tree: Record<string, G>,
  id: string | memoKey,
): G[] => {
  const res = list.map(name => tree[name]);
  if (process.env.NODE_ENV === 'development') {
    if (res.some(x => !x)) {
      console.warn(id, res, list);
    }
  }
  return res.filter(Boolean);
};

export const fnListSelector = <G, T>(
  tree: Record<string, G>,
  { list, id, fn }: FnOptions<G, T>,
): T =>
  ownMemoize(id, list, tree, (list, tree, id) =>
    fn(getElements(list, tree, id)),
  );
export const listSelector = <G>(
  tree: Record<string, G>,
  { list, id }: Options,
): G[] => ownMemoize(id, list, tree, getElements);
