/* eslint-disable no-param-reassign */
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';

import type { AnyId, IWidget, IWidgetAny } from '@board/shared/model';
import { shouldBeArr } from '@board/shared/utils';

import { devtools, immerMiddleware } from '../_shared_/zustand';

type State = {
  widgets: Record<string, Readonly<IWidgetAny>>;
  set(widget: IWidget | IWidget[]): void;
  remove(widget: IWidget | IWidget[]): void;
  reset(): void;
};

export const useWidgetStore = create<State>()(
  devtools(
    immerMiddleware(set => ({
      widgets: {},
      set: widgets =>
        set(state => {
          shouldBeArr(widgets).forEach(widget => {
            state.widgets[widget.id] = widget;
          });
        }),
      remove: widgets =>
        set(state => {
          shouldBeArr(widgets).forEach(widget => {
            delete state.widgets[widget.id];
          });
        }),
      reset: () =>
        set(state => {
          state.widgets = {};
        }),
    })),
    'widgets',
  ),
);

export const widgetSelector = {
  get: (id: number) => (state: State) => state.widgets[id],
  getProp:
    <T extends keyof IWidget>(id: number, prop: T) =>
    (state: State) => {
      return widgetSelector.get(id)(state)[prop];
    },
};

export const widgetAction = {
  storeSet: (payload: Parameters<State['set']>[0]) =>
    unstable_batchedUpdates(() => {
      useWidgetStore.getState().set(payload);
    }),
  storeRemove: (payload: Parameters<State['remove']>[0]) =>
    unstable_batchedUpdates(() => {
      useWidgetStore.getState().remove(payload);
    }),
  storeReset: () =>
    unstable_batchedUpdates(() => {
      useWidgetStore.getState().reset();
    }),
};

export const useWidgetDataGet = (id: AnyId): IWidget | undefined =>
  useWidgetStore(s => s.widgets[id]);
