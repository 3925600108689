import { useCallback, useState } from 'react';

import { onRequestError } from '@board/basics/alert';
import { type ILoader, loaderDefault } from '@board/shared/redux/loader';

import { useIsMounted } from './useIsMounted';

export enum LoaderAction {
  Pending,
  Success,
  Error,
}
const reducer = (state: ILoader, action: LoaderAction): ILoader => {
  switch (action) {
    case LoaderAction.Error:
      return {
        ...loaderDefault,
        error: true,
      };
    case LoaderAction.Success:
      return {
        ...loaderDefault,
        success: true,
      };
    case LoaderAction.Pending:
      return {
        ...loaderDefault,
        pending: true,
      };
    default:
      return loaderDefault;
  }
};
export const useLoader = () => {
  const [loader, setLoaderInt] = useState(loaderDefault);
  const isMounted = useIsMounted();

  const setLoader = useCallback((action: LoaderAction) => {
    if (isMounted.current) {
      setLoaderInt(state => reducer(state, action));
    }
  }, []);

  return [loader, setLoader] as const;
};

export const useAsyncFnWithLoader = <T>(
  fn: (firstArg: T) => Promise<unknown>,
) => {
  const [loader, setLoader] = useLoader();

  const onAction = useCallback((payload: T) => {
    setLoader(LoaderAction.Pending);
    fn(payload)
      .then(() => {
        setLoader(LoaderAction.Success);
      })
      .catch((e: unknown) => {
        onRequestError(e);
        setLoader(LoaderAction.Error);
      });
  }, []);
  return [onAction, loader] as const;
};
export const useAsyncCallbackWithLoader = <Payload, Response>(
  fn: (firstArg: Payload) => Promise<Response>,
  callback: (data: Response) => void,
) => {
  const [loader, setLoader] = useLoader();

  const onAction = useCallback(
    (payload: Payload) => {
      setLoader(LoaderAction.Pending);
      fn(payload)
        .then((data: Response) => {
          setLoader(LoaderAction.Success);
          callback(data);
        })
        .catch((e: unknown) => {
          onRequestError(e);
          setLoader(LoaderAction.Error);
        });
    },
    [callback],
  );
  return [onAction, loader] as const;
};
