export const round = (value: number, precision = 0) => {
  const multiplier = 10 ** precision;
  return Math.round(value * multiplier) / multiplier;
};

export const range = (countValue: number): number[] => {
  const count = Math.max(countValue, 0);
  let length = count;
  const arr = new Array(count);
  let index = -1;
  // eslint-disable-next-line no-plusplus
  while (length--) {
    index += 1;
    arr[index] = index;
  }
  return arr;
};
