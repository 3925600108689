import React, { memo, useEffect, useState } from 'react';

import { SpinnerGridCenter } from '@board/ui/Spinner';

import { PrivateRoutes } from '../PrivateRoutes';
import { PublicRoutes } from '../PublicRoutes';

import { Res, type UserAuthRes, createUserAuth } from './createUserAuth';

export const NavigateUser = memo(() => {
  const [res, setRes] = useState<UserAuthRes>();
  useEffect(() => {
    createUserAuth().then(res => {
      setRes(res);
    });
  }, []);
  if (res) {
    if (res[0] === Res.Error) {
      return <PublicRoutes />;
    }
    return <PrivateRoutes user={res[1]} />;
  }
  return <SpinnerGridCenter size={60} />;
});
