import { useEffect } from 'react';

import {
  DEFAULT_SCOPE,
  keyHandler,
  keyHandlerEverywhere,
} from '@board/basics/helpers/keys/keyHandler';

type Options = {
  skipOther: boolean;
};

const createUseKey =
  (handler: typeof keyHandler | typeof keyHandlerEverywhere) =>
  (
    command: string,
    action: (e: KeyboardEvent) => void,
    active = true,
    { skipOther }: Options = { skipOther: false },
  ) => {
    useEffect(() => {
      if (active) {
        handler.bind(command, DEFAULT_SCOPE, action, { skipOther });
        return () => {
          handler.unbind(command, DEFAULT_SCOPE, action);
        };
      }
      return undefined;
    }, [command, action, active]);
    return command;
  };
export const useKey = createUseKey(keyHandler);
export const useKeyEverywhere = createUseKey(keyHandlerEverywhere);

export const onEsc = (action: () => void, active = true) =>
  useKey('esc', action, active);
