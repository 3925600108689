import { unstable_batchedUpdates } from 'react-dom';

import { createStore } from '@board/dp';
import { LS, LSKeys } from '@board/shared/web';

type UiSettingStore = {
  isMobile: boolean;
  mobileDrawer: boolean;
  desktopDrawer: boolean;
  toggleDrawer(): void;
  setMobile(isMobile: boolean): void;
  primaryColor?: string | null;
  set(data: Partial<UiSettingStore>): void;
  floatWidget: number | null;
  tapFather: boolean;
};

const supportMatchMedia =
  typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';
// equivalent to useIsMobileQuery.ts theme.breakpoints.down('md')
const mobileQuery = '(max-width:899.95px)';

export const useUiSettingStore = createStore<UiSettingStore>(set => ({
  isMobile: supportMatchMedia ? window.matchMedia(mobileQuery).matches : false,
  primaryColor: undefined,
  tapFather: false,
  desktopDrawer: LS.has(LSKeys.showDrawer) ? LS.bool(LSKeys.showDrawer) : true,
  mobileDrawer: false,
  floatWidget: null,
  toggleDrawer: () =>
    set(state => {
      if (state.isMobile) {
        return { ...state, mobileDrawer: !state.mobileDrawer };
      }
      LS.insert(LSKeys.showDrawer, !state.desktopDrawer);
      return {
        desktopDrawer: !state.desktopDrawer,
      };
    }),
  setMobile: isMobile =>
    set({
      isMobile,
      mobileDrawer: false,
    }),
  set: data => set(state => ({ ...state, ...data })),
}));

export const uiSelector = {
  primaryColor: (s: UiSettingStore) => s.primaryColor,
  set: (s: UiSettingStore) => s.set,
  floatWidget: (s: UiSettingStore) => s.floatWidget,
  showDrawerBoardIcon: (s: UiSettingStore) =>
    s.isMobile ? !s.mobileDrawer : !s.desktopDrawer,
  isTapFatherEnabled: (s: UiSettingStore) => s.tapFather,
};

type UiNonUserStore = {
  hideTopNav: boolean;
  setHideTopNav(value: boolean): void;
};

export const useUiNonUserStore = createStore<UiNonUserStore>(set => ({
  hideTopNav: false,
  setHideTopNav: (value: boolean) =>
    set(state => ({
      ...state,
      hideTopNav: value,
    })),
}));

export const uiNonUserAction = {
  setHideTopNav: (value: boolean) =>
    unstable_batchedUpdates(() => {
      useUiNonUserStore.getState().setHideTopNav(value);
    }),
};
