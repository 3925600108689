import type { IUser } from './user';

export interface IFeedback {
  id: number;
  userId: number | null;
  form: IFeedbackIt1 | IFeedbackIt2;
  ip?: string;
  iteration: FeedbackIteration;
  createdAt: string;
}

export interface IFeedbackWithUser extends IFeedback {
  user: IUser;
}

export enum FeedbackIteration {
  July02 = 1,
  NovEnd,
}
export enum feedbackKeys {
  id = 'id',
  ip = 'ip',
  iteration = 'iteration',
  form = 'form',
  userId = 'userId',
}
export enum FeedbackIt1Keys {
  Satisfaction,
  Experience,
  Help,
  ExperienceOther,
  Tools,
  ToolsOther,
  More,
}
export enum FeedbackIt2Keys {
  Good = 'like',
  Bad = 'dislike',
  Change = 'change',
}
export type IFeedbackIt1 = {
  [FeedbackIt1Keys.Satisfaction]: number | null;
  [FeedbackIt1Keys.Experience]: string[];
  [FeedbackIt1Keys.ExperienceOther]: string;
  [FeedbackIt1Keys.Help]: string;
  [FeedbackIt1Keys.Tools]: string[];
  [FeedbackIt1Keys.ToolsOther]: string;
  [FeedbackIt1Keys.More]: string;
};

export type IFeedbackIt2 = {
  [FeedbackIt2Keys.Good]: string;
  [FeedbackIt2Keys.Bad]: string;
  [FeedbackIt2Keys.Change]: string;
};

export enum feedbackRelation {
  User = 'user',
}
