export function isBoolean<T>(
  value: T,
): value is Exclude<T, false | null | undefined | '' | 0> {
  return Boolean(value);
}

// eslint-disable-next-line no-confusing-arrow
export const shouldBeArr = <T>(arr: T | T[]): T[] =>
  Array.isArray(arr) ? arr : [arr];

export const reorder = <T>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const sortByPosition = <T extends { position: number | null }>(
  list: T[],
): T[] => {
  list.sort((a: T, b: T) => (a.position || 0) - (b.position || 0));
  return list;
};

const insert = <T>(list: T[], index: number, element: T): T[] => [
  ...list.slice(0, index),
  element,
  ...list.slice(index),
];
const replace = <T>(list: T[], index: number, element: T): T[] => [
  ...list.slice(0, index),
  element,
  ...list.slice(index + 1),
];
const remove = <T>(list: T[], index: number): T[] => [
  ...list.slice(0, index),
  ...list.slice(index + 1),
];
const addAndReorder = <T>(list: T[], index: number, element: T): T[] => {
  return reorder([...list, element], list.length, index);
};

const getLast = <T>(list: T[]): T | null => list[list.length - 1];

const getNextIndex = <T>(list: T[], index: number): number | null => {
  if (index === -1 || !list.length) return null;
  if (index >= list.length - 1) return 0;
  return index + 1;
};
const getNextByIndex = <T>(list: T[], index: number): T | null => {
  const nextIndex = getNextIndex(list, index);
  return nextIndex === null ? null : list[nextIndex];
};

const getNext = <T>(list: T[], current: T): T | null => {
  const index = list.indexOf(current);
  return getNextByIndex(list, index);
};

const getPrevIndex = <T>(list: T[], index: number): number | null => {
  if (index === -1 || !list.length) return null;
  if (index === 0) return list.length - 1;
  return index - 1;
};
const getPrevByIndex = <T>(list: T[], index: number): T | null => {
  const prevIndex = getPrevIndex(list, index);
  return prevIndex === null ? null : list[prevIndex];
};

const getPrev = <T>(list: T[], current: T): T | null => {
  const index = list.indexOf(current);
  return getPrevByIndex(list, index);
};

const toObject = <T extends string | number, Value>(
  list: T[],
  defaultValue: Value,
) => {
  return list.reduce(
    (acc, value) => {
      acc[value] = defaultValue;
      return acc;
    },
    {} as Record<T, Value>,
  );
};

export const arrayHelper = {
  insert,
  remove,
  replace,
  reorder,
  addAndReorder,
  sortByPosition,
  getNext,
  getNextByIndex,
  getNextIndex,
  getPrev,
  getPrevByIndex,
  getPrevIndex,
  getLast,
  toObject,
};
