import React from 'react';

import type { IUser } from '@board/shared/model/user';
import { LoadableComponent } from '@board/ui/LoadableComponent';

const Component = React.lazy(() => import('./PrivateRoutes'));

export const PrivateRoutes = ({ user }: { user: IUser }) => (
  <LoadableComponent>
    <Component user={user} />
  </LoadableComponent>
);
