import { planKeys } from './user';

export enum Products {
  proAnnually = 'boardist-pro-annually',
  proMonthly = 'boardist-pro-montly',
  collaborateAnnually = 'annually-collaborate-plan',
  collaborateMonthly = 'monthly-collaborate-plan',
}

export enum FsTypes {
  activated = 'subscription.activated',
  deactivated = 'subscription.deactivated',
  canceled = 'subscription.canceled',
  uncanceled = 'subscription.uncanceled',
  updated = 'subscription.updated',
  paymentOverdue = 'subscription.payment.overdue',
  accountCreated = 'account.created',
}

export enum FSResult {
  success = 'success',
  error = 'error',
}

export enum paymentKeys {
  id = 'id',
  fsId = 'fsId',
  type = 'type',
  userId = 'userId',
  product = 'product',
  result = 'result',
  data = 'data',
}

export interface IPaymentCreate {
  fsId: string;
  userId: number;
  type: FsTypes;
  product: Products;
  result: FSResult;
  data: FSSubscription;
}
export interface IPayment extends IPaymentCreate {
  id: number;
}

export const productToPlanKey: {
  [key in Products]: Exclude<planKeys, planKeys.free>;
} = {
  [Products.proMonthly]: planKeys.unlimited,
  [Products.proAnnually]: planKeys.unlimited,
  [Products.collaborateMonthly]: planKeys.beta,
  [Products.collaborateAnnually]: planKeys.beta,
};

export const planProducts: {
  [key in Exclude<planKeys, planKeys.free>]: [Products, Products];
} = {
  [planKeys.beta]: [Products.collaborateAnnually, Products.collaborateMonthly],
  [planKeys.unlimited]: [Products.proAnnually, Products.proMonthly],
};

export const planPricing: { [key in planKeys]: number } = {
  [planKeys.free]: 0,
  [planKeys.beta]: 3,
  [planKeys.unlimited]: 8,
};

interface Base {
  id: string;
  processed: boolean;
  live: boolean;
}

export interface FsAccount {
  id: string;
  account: string;
  contact: {
    first: string;
    last: string;
    email: string;
    company: string | null;
    phone: string;
  };
  language: string;
  country: string;
  lookup: {
    global: string;
    custom?: string;
  };
  url: string;
  orders: string[];
  subscriptions: string[];
}

export interface FsAccountWithId extends FsAccount {
  boardistId: number;
}

export interface FsAccountLookup {
  action: 'account.lookup';
  result: FSResult;
  accounts: FsAccount[];
}

/**
 "id": "4MHaKT0OQtW_wRRDcTOC6w",
 "processed": false,
 "created": 1580822567282,
 "type": "account.created",
 "live": true,
 "data": {
        "id": "iVmYnDdGTVui745OA79HwQ",
        "account": "iVmYnDdGTVui745OA79HwQ",
        "contact": {
          "first": "asas",
          "last": "sdwq",
          "email": "gs@gm.com",
          "company": null,
          "phone": "222"
        },
        "language": "en",
        "country": "UA",
        "lookup": {
          "global": "KYiA8z-cQh2uUcE9WSdJZA"
        },
        "url": "https://elvin.test.onfastspring.com/account"
      }
 */
interface AccountCreated extends Base {
  type: FsTypes.accountCreated;
  data: {
    id: string;
    account: string;
    contact: {
      first: string;
      last: string;
      email: string;
      company: string | null;
      phone: string;
    };
    language: string;
    country: string;
    lookup: {
      global: string;
    };
    url: string;
  };
}

export interface FSSubscription {
  id: string;
  active: boolean;
  state: 'active' | 'canceled';
  live: boolean;
  product: Products;
  account: string;
  subtotal: number;
  quantity: number;
  next: number;
  nextDisplay: string;
  autoRenew: boolean;
  price: number;
  priceDisplay: number;
  begin: string;
  beginDisplay: string;
  result: FSResult;
  tags?: {
    userId: string;
  };
}
interface FSOrderItem {
  product: Products;
  subscription: string;
}

export interface FSOrder {
  id: string;
  completed: boolean;
  account: string;
  items: FSOrderItem[];
  result: FSResult;
}

/**
 "id": "NpPqL3t7RlmVS36zhgRDqA",
 "processed": false,
 "created": 1580822567770,
 "type": "subscription.activated",
 "live": false,
 "data": {
        "id": "_fs2LsSTQQG9Jp6y3nPUkg",
        "subscription": "_fs2LsSTQQG9Jp6y3nPUkg",
        "active": true,
        "state": "active",
        "changed": 1580822567693,
        "changedValue": 1580822567693,
        "changedInSeconds": 1580822567,
        "changedDisplay": "2/4/20",
        "live": false,
        "currency": "USD",
        "account": "iVmYnDdGTVui745OA79HwQ",
        "product": "boardist-pro-annually",
        "sku": null,
        "display": "Boardist PRO annually",
        "quantity": 1,
        "adhoc": false,
        "autoRenew": true,
        "price": 96,
        "priceDisplay": "$96.00",
        "priceInPayoutCurrency": 96,
        "priceInPayoutCurrencyDisplay": "$96.00",
        "discount": 0,
        "discountDisplay": "$0.00",
        "discountInPayoutCurrency": 0,
        "discountInPayoutCurrencyDisplay": "$0.00",
        "subtotal": 96,
        "subtotalDisplay": "$96.00",
        "subtotalInPayoutCurrency": 96,
        "subtotalInPayoutCurrencyDisplay": "$96.00",
        "next": 1612396800000,
        "nextValue": 1612396800000,
        "nextInSeconds": 1612396800,
        "nextDisplay": "2/4/21",
        "end": null,
        "endValue": null,
        "endInSeconds": null,
        "endDisplay": null,
        "canceledDate": null,
        "canceledDateValue": null,
        "canceledDateInSeconds": null,
        "canceledDateDisplay": null,
        "deactivationDate": null,
        "deactivationDateValue": null,
        "deactivationDateInSeconds": null,
        "deactivationDateDisplay": null,
        "sequence": 1,
        "periods": null,
        "remainingPeriods": null,
        "begin": 1580774400000,
        "beginValue": 1580774400000,
        "beginInSeconds": 1580774400,
        "beginDisplay": "2/4/20",
        "intervalUnit": "year",
        "intervalLength": 1,
        "nextChargeCurrency": "USD",
        "nextChargeDate": 1612396800000,
        "nextChargeDateValue": 1612396800000,
        "nextChargeDateInSeconds": 1612396800,
        "nextChargeDateDisplay": "2/4/21",
        "nextChargePreTax": 96,
        "nextChargePreTaxDisplay": "$96.00",
        "nextChargePreTaxInPayoutCurrency": 96,
        "nextChargePreTaxInPayoutCurrencyDisplay": "$96.00",
        "nextChargeTotal": 96,
        "nextChargeTotalDisplay": "$96.00",
        "nextChargeTotalInPayoutCurrency": 96,
        "nextChargeTotalInPayoutCurrencyDisplay": "$96.00",
        "nextNotificationType": "PAYMENT_REMINDER",
        "nextNotificationDate": 1611792000000,
        "nextNotificationDateValue": 1611792000000,
        "nextNotificationDateInSeconds": 1611792000,
        "nextNotificationDateDisplay": "1/28/21",
        "paymentReminder": {
          "intervalUnit": "week",
          "intervalLength": 1
        },
        "paymentOverdue": {
          "intervalUnit": "week",
          "intervalLength": 1,
          "total": 4,
          "sent": 0
        },
        "cancellationSetting": {
          "cancellation": "AFTER_LAST_NOTIFICATION",
          "intervalUnit": "week",
          "intervalLength": 1
        },
        "instructions": [
          {
            "product": "boardist-pro-annually",
            "type": "regular",
            "periodStartDate": null,
            "periodStartDateValue": null,
            "periodStartDateInSeconds": null,
            "periodStartDateDisplay": null,
            "periodEndDate": null,
            "periodEndDateValue": null,
            "periodEndDateInSeconds": null,
            "periodEndDateDisplay": null,
            "intervalUnit": "year",
            "intervalLength": 1,
            "discountPercent": 0,
            "discountPercentValue": 0,
            "discountPercentDisplay": "0%",
            "discountTotal": 0,
            "discountTotalDisplay": "$0.00",
            "discountTotalInPayoutCurrency": 0,
            "discountTotalInPayoutCurrencyDisplay": "$0.00",
            "unitDiscount": 0,
            "unitDiscountDisplay": "$0.00",
            "unitDiscountInPayoutCurrency": 0,
            "unitDiscountInPayoutCurrencyDisplay": "$0.00",
            "price": 96,
            "priceDisplay": "$96.00",
            "priceInPayoutCurrency": 96,
            "priceInPayoutCurrencyDisplay": "$96.00",
            "priceTotal": 96,
            "priceTotalDisplay": "$96.00",
            "priceTotalInPayoutCurrency": 96,
            "priceTotalInPayoutCurrencyDisplay": "$96.00",
            "unitPrice": 96,
            "unitPriceDisplay": "$96.00",
            "unitPriceInPayoutCurrency": 96,
            "unitPriceInPayoutCurrencyDisplay": "$96.00",
            "total": 96,
            "totalDisplay": "$96.00",
            "totalInPayoutCurrency": 96,
            "totalInPayoutCurrencyDisplay": "$96.00"
          }
        ]
      }
 */

export interface FsSubscriptionHook extends Base {
  type:
    | FsTypes.activated
    | FsTypes.canceled
    | FsTypes.uncanceled
    | FsTypes.updated
    | FsTypes.deactivated
    | FsTypes.paymentOverdue;
  data: FSSubscription;
}

export type FsHook = FsSubscriptionHook;
