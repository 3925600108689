import type { IBoard } from './board';
import type { TrelloBoardOutput } from './import/trello';

export enum importServices {
  trello = 'trello',
  todoist = 'todoist',
}
export enum importServiceKeys {
  id = 'id',
  service = 'service',
  token = 'token',
  userId = 'userId',
}

export enum importServicesFind {
  isValid = 'isValid',
  data = 'data',
}

export interface IImportServiceDraft {
  service: importServices;
  token: string;
}

export interface IImportService extends IImportServiceDraft {
  id: number;
  userId: number;
}

export type ImportCreateRes = {
  boards: IBoard[];
};

export interface IImportFind {
  service: importServices;
  type: importServicesFind;
}

export type IImportFindRes = ImportServiceOutput[];

export type ImportServiceOutput = TrelloBoardOutput;

export type ImportBoardsCreate = {
  service: importServices.trello | importServices.todoist;
  data: ImportServiceOutput[];
};
