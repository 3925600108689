/* eslint-disable @typescript-eslint/ban-types */
export const getKeys = <T extends {}>(o: T): Array<keyof T> =>
  Object.keys(o) as Array<keyof T>;

export function assign<B, E>(base: B & Partial<E>, extension: E): B & E {
  return Object.assign(base, extension);
}
export const isObjectLike = (value: any): value is {} =>
  typeof value === 'object' && value !== null;

export function hasOwn<O extends object, K extends PropertyKey>(
  obj: O,
  key: K,
): obj is ObjectWithKey<O, K> {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

/**
 * This utility type takes an object type O and a key type K. If K is a known
 * key on O, it evaluates to the original object O. Otherwise, it evaluates to
 * the object O with an additional key K that has an `unknown` value.
 */
type ObjectWithKey<O extends object, K extends PropertyKey> = K extends keyof O
  ? O
  : O & Record<K, unknown>;

export const omitBy = (
  obj: Record<string | number, any>,
  fn: (value: any, key: string) => boolean,
) =>
  Object.keys(obj)
    .filter(k => !fn(obj[k], k))
    .reduce((acc: Record<string | number, any>, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

export const omitNulls = (obj: { [key: string]: any }) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      if (obj[key] === null) {
        // eslint-disable-next-line no-param-reassign
        delete obj[key];
      }
    }
  }
};
