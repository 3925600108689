/* eslint-disable no-param-reassign */

import { isMemoryHistory } from '@board/basics/route';

import { boardAction, useBoardStore } from './boardStore';
import { middleware } from './middleware';
import { useUserStore } from './userStore';

let lastFetched = 0;
// fetch current board widgets
useBoardStore.subscribe(({ current }) => {
  if (current != null && current !== lastFetched) {
    lastFetched = current;

    middleware.syncCurrentBoard(current);
  }
});

let lastUser: null | number = null;

// fetch all user boards
useUserStore.subscribe(({ user }) => {
  if (user && user?.id !== lastUser) {
    lastUser = user.id;
    middleware.syncBoards(user.email).then(() => {
      if (!isMemoryHistory && window.location.pathname === '/') {
        boardAction.notFoundRedirect();
      } else {
        boardAction.currentExistOrRedirect();
      }
    });
  }
});
