export enum FeatherEvents {
  get = 'get',
  find = 'find',
  create = 'create',
  remove = 'remove',
  patch = 'patch',
  update = 'update',
}

export const restMethods = {
  [FeatherEvents.get]: 'GET',
  [FeatherEvents.find]: 'GET',
  [FeatherEvents.create]: 'POST',
  [FeatherEvents.update]: 'PUT',
  [FeatherEvents.patch]: 'PATCH',
  [FeatherEvents.remove]: 'DELETE',
};

export type FeatherFuncEvents = Exclude<FeatherEvents, 'find'>;

export const featherEventEmitters = {
  [FeatherEvents.get]: 'get',
  [FeatherEvents.create]: 'created',
  [FeatherEvents.remove]: 'removed',
  [FeatherEvents.patch]: 'patched',
  [FeatherEvents.update]: 'updated',
} as const;

type QueryItem<T> =
  | Partial<T>
  | { [key in keyof T]?: { $ilike: T[key] | string } };

export type FeatherQuery<T> = QueryItem<T> | { $or: Array<QueryItem<T>> };
