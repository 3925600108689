import type { AwsType } from './aws';
import type { planKeys } from './user';

export enum profileGetTypes {
  storage = 'storage',
  securePayment = 'securePayment',
  validateSubscription = 'validateSubscription',
  sharedBookmarks = 'shared',
  awsPutKey = 'awsPutKey',
}

export type UserStorage = {
  used: number;
  total: number;
  available: number;
};

export interface SecurePayment {
  payload: string;
  key: string;
}

export type ProfileValidateSubscription = {
  type: profileGetTypes.validateSubscription;
  id: string;
  plan: Exclude<planKeys, planKeys.free>;
};

export type GetSharedBookmarks = {
  type: profileGetTypes.sharedBookmarks;
  id: number;
};

export type ProfileSecurePayment = {
  type: profileGetTypes.securePayment;
  plan: planKeys;
  isAnnually: boolean;
  planFactor: number;
};

export type GetAwsPutKey = {
  type: profileGetTypes.awsPutKey;
  file: {
    name: string;
    size: number;
    contentType?: string;
    type: AwsType;
  };
};

export type ProfileQuery =
  | { type: profileGetTypes.storage }
  | ProfileSecurePayment
  | GetAwsPutKey
  | GetSharedBookmarks
  | ProfileValidateSubscription;
