import qs from 'query-string';

import { getApiAsApp } from '@board/basics/api';
import type { AuthPayload, IUser } from '@board/shared/model/user';
import { serviceNames } from '@board/shared/serviceNames';
import { isTokenExpires } from '@board/shared/utils';
import { redirect } from '@board/shared/web';

export enum Res {
  Error,
  Success,
}

export type UserAuthRes = [Res.Success, IUser] | [Res.Error, Error];

export const createUserAuth = (): Promise<UserAuthRes> => {
  return new Promise(res => {
    const api = getApiAsApp();
    api
      .authenticate()
      .then(({ user, authentication, accessToken }) => {
        res([Res.Success, user]);
        const auth = authentication as AuthPayload;
        const exp = auth?.payload?.exp;

        const search = qs.parse(window.location.search);
        if (typeof search.redirect === 'string') {
          // redirect to provided link
          // eslint-disable-next-line no-nested-ternary
          const separator = search.redirect.endsWith('?')
            ? ''
            : search.redirect.indexOf('#') !== -1
              ? '?'
              : '#';

          const redirectLink =
            search.redirect +
            separator +
            qs.stringify({
              authToken: accessToken,
            });
          redirect(redirectLink);
        }

        if (exp) {
          (async () => {
            if (isTokenExpires(exp)) {
              try {
                const { accessToken } = await api
                  .service(serviceNames.refreshToken)
                  .create({});
                if (accessToken) {
                  await api.authentication.setAccessToken(accessToken);
                }
              } catch (e) {
                // do nothing
              }
            }
          })();
        }
      })
      .catch(e => {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.log(e);
        }
        if (e.code === 404) {
          api.authentication.removeAccessToken();
        }
        res([Res.Error, e]);
      });
  });
};
