// eslint-disable-next-line import/no-unresolved
import type { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';
import type { SerializedEditorState } from 'lexical';
import type { Optional } from 'utility-types';

import type { BinaryFiles } from '@excalidraw/excalidraw/types/types';
import type { AwsBucket } from './aws';
import type { IBoardWithoutLayout } from './board';
import type { IItemComment, IItemCommentDraft } from './itemComment';
import type { IWidget } from './widget';
import { type widgetKeys, widgetTypes } from './widget';

export enum ItemKey {
  id = 'id',
  value = 'value',
  widgetId = 'widgetId',
  userId = 'userId',
  position = 'position',
  data = 'data',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export interface ItemDraft<Data = unknown> {
  value: string;
  data: Data;
  position: number;
  userId: number;
  widgetId: number;
}

export type ItemCreate<Item extends ItemAny> = Pick<Item, 'value' | 'data'>;

export type ItemsCreate<Item extends ItemAny> = {
  widgetId: number;
  items: Array<ItemCreate<Item>>;
};

export interface IItem<Data> extends ItemDraft<Data> {
  id: number;
  data: Data;
  createdAt: string;
  updatedAt: string;
}

export type ItemAny = IItem<unknown>;

export interface ItemFull extends IItem<unknown> {
  [itemRelation.comments]?: IItemComment[];
  [itemRelation.history]?: ItemHistoryAny[];
}

export type IItemWithWidget<G = ItemAny> = G extends ItemAny
  ? IItem<G['data']> & { widget: IWidget }
  : never;

export interface IItemWithWidgetAndBoard<G extends ItemAny>
  extends IItem<G['data']> {
  board: IBoardWithoutLayout;
  // with widgetModifier.NameIdType
  widget: Pick<
    IWidget,
    widgetKeys.id | widgetKeys.type | widgetKeys.archived | widgetKeys.title
  >;
}

export interface ItemsCreateResponse<G extends IItem<unknown>> {
  widgetId: number;
  items: G[];
  created: { [i: number]: true };
}
export type ItemsRemoveResponse<G extends IItem<unknown> = ItemAny> = G | G[];
export type ItemsRemoveUiResponse<G extends IItem<unknown> = ItemAny> = G;

export enum itemRelation {
  widget = 'widget',
  board = 'board',
  comments = 'comments',
  history = 'history',
}

export enum ItemHistoryKey {
  id = 'id',
  itemId = 'itemId',
  userId = 'userId',
  value = 'value',
  data = 'data',
  time = 'time',
}

export interface IItemHistoryDraft {
  value: string;
  data?: unknown;
  itemId: number;
  userId: number;
}

export interface IItemHistory<Data> extends IItemHistoryDraft {
  id: number;
  time: Date;
  data: Data;
}

export type IItemDocHistory = IItemHistory<IItemDoc['data']>;

export type ItemHistoryAny = IItemDocHistory;

export enum ItemTaskColor {
  Red,
  Yellow,
}

export type IItemTask = IItem<{
  completed: boolean;
  bin: boolean;
  color?: ItemTaskColor;
} | null>;

export type IItemDocV2 = IItem<
  Optional<SerializedEditorState> & { next?: true; session?: string }
>;
export type IItemDoc = IItemDocV2;

export type IItemFile = IItem<{
  path: string;
  size: number;
  bucket: AwsBucket;
}>;

export type IItemBookmark = IItem<{
  host: string;
  logo?: string;
  title?: string;
  customTitle?: string;
}>;
export type IItemBookmarkDraft = ItemDraft<IItemBookmark['data']>;
export interface IScrapperUrl {
  url: string;
  title: string;
  logo: string | null;
}

export interface ICreateBookmark {
  host: string;
  logo?: string;
  title?: string;
  url: string;
}

export type IItemDraw = IItem<{
  elements?: readonly ExcalidrawElement[];
  preview?: string | ArrayBuffer;
  files?: BinaryFiles;
}>;

export type IItemCombo = IItem<{ widgetId: number }>;

export type IItemGraph<T extends ItemAny> = Omit<
  T,
  'widgetId' | 'createdAt' | 'updatedAt' | 'id'
> & {
  data: T['data'] extends never ? undefined : T['data'];
  comments?: Array<Omit<IItemCommentDraft, 'itemId'>>;
};

export type ItemCreateRevision = {
  id: number;
  itemId: number;
};

export enum ItemExtrasType {
  FixOrder,
}
export type ItemExtrasCreate = {
  type: ItemExtrasType.FixOrder;
  widgetId: number | string;
};

export type WidgetTypeToItemData = {
  [widgetTypes.bookmarks]: IItemBookmark;
  [widgetTypes.tasks]: IItemTask;
  [widgetTypes.documents]: IItemDoc;
  [widgetTypes.files]: IItemFile;
  [widgetTypes.excalidraw]: IItemDraw;
};
