export const scrollTop = () => window.scrollTo(0, 0);
export const scrollBottom = () =>
  window.scrollTo(
    0,
    window.document.scrollingElement
      ? window.document.scrollingElement.scrollHeight - window.innerHeight
      : 0,
  );

export const scrollToBottom = (el: HTMLDivElement | null) => {
  if (!el) {
    return;
  }
  el.scrollTo(0, el.scrollHeight);
};

export const fixWidgetHeight = (height?: string, min = 50) => {
  if (height) {
    const num = Number.parseFloat(height);
    if (!Number.isNaN(num)) {
      return Math.max(num - 45, min);
    }
  }
  return min;
};

const setMetaContent = (metaName: string, content: string) => {
  const element = document.querySelector(`meta[name="${metaName}"]`);
  if (element != null) {
    element.setAttribute('content', content);
    return;
  }
  const meta = document.createElement('meta');
  meta.name = metaName;
  meta.content = content;
  document.head.appendChild(meta);
};

export const setDocumentBg = (hex: string) => {
  setMetaContent('theme-color', hex);
  setMetaContent('apple-mobile-web-app-status-bar-style', hex);
  // if (!hex) {
  //   document.body.style.removeProperty('background');
  // } else {
  //   document.body.style.background = hex;
  // }
};

export const downloadJson = (name: string, json: unknown) => {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(json, null, 4),
  )}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${name}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};
