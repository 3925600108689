export enum AttemptsType {
  Login,
  RestorePwd,
}
export enum attemptsKeys {
  id = 'id',
  email = 'email',
  attempts = 'attempts',
  updatedAt = 'updatedAt',
  type = 'type',
}

export interface IAttemptsCreate {
  email: string;
  type: AttemptsType;
  attempts: number;
}

export interface IAttempts extends IAttemptsCreate {
  id: number;
  updatedAt: string;
}
