/* eslint-disable no-param-reassign */
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';

import { devtools, immerMiddleware } from '../_shared_/zustand';

export enum BestNavLevel {
  AddBoard,
  AddWidget,
  EditBoard,
  BoardsArchive,
  BoardsReorder,
  NewTasks,
  UserSettings,
}

export enum EditBoardLevel {
  Settings,
  History,
  Share,
  Archive,
}

export type LevelEditBoard = {
  type: BestNavLevel.EditBoard;
  subType: EditBoardLevel;
  id: number;
  name: string;
};

export type BestNavSimpleLevels = {
  type: Exclude<BestNavLevel, BestNavLevel.EditBoard>;
  name: string;
};
export type BestNavLevelAny = LevelEditBoard | BestNavSimpleLevels;

export enum CloseQSType {
  None,
  WarnFirst,
  Warned,
  Close,
}

type State = {
  level: BestNavLevelAny | null;
  lastUsedLevel: BestNavLevelAny | null;
  search: string;
  lastSearch: string;
  closeType: CloseQSType;
  setCloseType(closeType: CloseQSType): void;
  setSearch(search: string): void;
  setLevel(level: BestNavLevelAny): void;
  setLastUsedLevel(): void;
  resetLevel(): void;
};
export const useBestNavStore = create<State>()(
  devtools(
    immerMiddleware(set => ({
      level: null,
      lastUsedLevel: null,
      search: '',
      lastSearch: '',
      closeType: CloseQSType.None,
      setCloseType: closeType =>
        set(state => {
          state.closeType = closeType;
        }),
      setSearch: search =>
        set(state => {
          state.search = search;
        }),
      setLevel: level =>
        set(state => {
          state.level = level;

          state.lastSearch = state.search;
          state.search = '';
        }),
      resetLevel: () =>
        set(state => {
          state.lastUsedLevel = state.level;
          state.level = null;

          state.search = state.lastSearch;
          state.lastSearch = '';
        }),
      setLastUsedLevel: () =>
        set(state => {
          state.level = state.lastUsedLevel;
          state.lastUsedLevel = null;

          state.lastSearch = state.search;
          state.search = '';
        }),
    })),
    'bestNav',
  ),
);

export const bestNavAction = {
  setLevel: (payload: Parameters<State['setLevel']>[0]) =>
    unstable_batchedUpdates(() => {
      useBestNavStore.getState().setLevel(payload);
    }),
  resetLevel: () =>
    unstable_batchedUpdates(() => {
      useBestNavStore.getState().resetLevel();
    }),
  setLastUsedLevel: () =>
    unstable_batchedUpdates(() => {
      useBestNavStore.getState().setLastUsedLevel();
    }),
};
