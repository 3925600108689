/* eslint-disable no-param-reassign */
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';

import { fnListSelector, memoKey } from '@board/basics/helpers/selectors';
import type { WidgetFavorite } from '@board/shared/model';
import { arrayHelper, shouldBeArr } from '@board/shared/utils';

import { immerMiddleware } from '../_shared_/zustand';

type State = {
  widgets: Record<string, WidgetFavorite>;
  set(widgets: WidgetFavorite | WidgetFavorite[]): void;
  remove(fav: WidgetFavorite): void;
};
export const useFavoriteWidgetStore = create<State>()(
  immerMiddleware(set => ({
    widgets: {},
    set: favs =>
      set(state => {
        shouldBeArr(favs).forEach(fav => {
          state.widgets[fav.widgetId] = fav;
        });
      }),
    remove: fav => {
      set(state => {
        delete state.widgets[fav.widgetId];
      });
    },
  })),
);

export const favoriteWidgetSelector = {
  get: (id: number) => (state: State) => state.widgets[id],
  has:
    (id: number) =>
    (state: State): boolean =>
      Boolean(favoriteWidgetSelector.get(id)(state)),
  list: (state: State) =>
    fnListSelector(state.widgets, {
      id: memoKey.favoriteWidgets,
      list: Object.keys(state.widgets),
      fn: arrayHelper.sortByPosition,
    }),
};

export const favoriteWidgetAction = {
  set: (payload: Parameters<State['set']>[0]) =>
    unstable_batchedUpdates(() => {
      useFavoriteWidgetStore.getState().set(payload);
    }),
  remove: (payload: Parameters<State['remove']>[0]) =>
    unstable_batchedUpdates(() => {
      useFavoriteWidgetStore.getState().remove(payload);
    }),
};
