export enum codeConfirmKeys {
  id = 'id',
  code = 'code',
  createdAt = 'createdAt',
  type = 'type',
  userId = 'userId',
}

export enum CodeConfirmType {
  AccountCreate,
  Password,
  Feedback,
  Unsubscribe,
}

export interface ICodeConfirmCreate {
  type: CodeConfirmType;
  userId: number;
}
export interface ICodeConfirmDraft extends ICodeConfirmCreate {
  code: string;
}
export interface ICodeConfirm extends ICodeConfirmDraft {
  id: number;
  createdAt: string;
}
