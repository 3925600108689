import type { IUser } from './user';

export enum onlineKeys {
  id = 'id',
  userId = 'userId',
  method = 'method',
  path = 'path',
  start = 'start',
  end = 'end',
}

export interface IOnline {
  id: number;
  userId: number;
  method: string;
  path: string;
  start: string;
  end: string;
}

export interface IOnlineWithUser extends IOnline {
  user: IUser;
}

export enum onlineRelation {
  user = 'user',
}
