export type ValueOf<T> = T[keyof T];
export type Unpacked<T> = T extends (infer U)[] ? U : T;

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

let getStackTrace: (e: TypedError, err: Error | string) => void;
if (Error.captureStackTrace != null) {
  const { captureStackTrace } = Error;
  getStackTrace = e => {
    captureStackTrace(e, e.constructor);
  };
} else {
  getStackTrace = (e, err) => {
    let error: Error;
    if (!(err instanceof Error)) {
      error = new Error(err);
    } else {
      error = err;
    }
    if (error.stack != null) {
      // @ts-ignore
      e.stack = error.error;
    }
  };
}

export class TypedError extends Error {
  public name: string;

  public message: string;

  public stack = '';

  constructor(err: Error | string = '') {
    super();
    if (err instanceof Error) {
      this.message = err.message;
    } else {
      this.message = err;
    }
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = this.constructor.name;
    getStackTrace(this, err);
  }
}

export const BError = {
  UnknownError: class UnknownError extends TypedError {},
};
