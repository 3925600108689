import { useCallback } from 'react';

import { onRequestError } from '@board/basics/alert';
import { useUsersService } from '@board/basics/useApiContext';
import type { IUser } from '@board/shared/model';

import { useUserStore, userSelector } from './store';

export const useUserUpdate = () => {
  const userService = useUsersService();

  return useCallback(
    (user: Partial<IUser>) => {
      userService
        .patch(userSelector.id(useUserStore.getState()), user)
        .catch(onRequestError);
    },
    [userService],
  );
};

export const useUserUpdateConfig = () => {
  const userUpdate = useUserUpdate();

  return useCallback(
    (config: Partial<IUser['config']>) => {
      userUpdate({
        config: {
          ...userSelector.getProp('config')(useUserStore.getState()),
          ...config,
        },
      });
    },
    [userUpdate],
  );
};

export const useIsAdmin = (): boolean => {
  return useUserStore(userSelector.getProp('isAdmin' as any));
};
