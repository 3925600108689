import { useCallback } from 'react';

import { useFeathersCreate } from 'hooks/useFeathersEvent';

import { type RequestError, onRequestError } from '@board/basics/alert';
import { getApi } from '@board/basics/api';
import { goTo } from '@board/basics/route';
import {
  useFeathersRemoveCallback,
  useFeathersUpdate,
} from '@board/hooks/useFeathers';
import { useOnSuccess } from '@board/hooks/useOnSuccess';
import type { IBoard } from '@board/shared/model';
import { serviceNames } from '@board/shared/serviceNames';

import { useBoardStore } from './store';

export const useBoardCreate = () => {
  const [onCreateInt, loader, data] = useFeathersCreate<
    { name: string },
    IBoard
  >(serviceNames.boards);

  useOnSuccess(loader, () => data && goTo.board(data.id));

  const onCreate = useCallback(
    (name: string) => onCreateInt({ name }),
    [onCreateInt],
  );

  return [onCreate, loader, data] as const;
};

export const useBoardRemove = () => {
  return useFeathersRemoveCallback(serviceNames.boards);
};

export const useBoardUpdate = (id: number) => {
  return useFeathersUpdate<IBoard>(serviceNames.boards, id);
};

export const boardApiUpdate = (
  id: number,
  payload: Partial<Omit<IBoard, 'id'>>,
) =>
  getApi()
    .service(serviceNames.boards)
    .patch(id, payload)
    .catch((e: RequestError) => {
      onRequestError(e);
    });

export const useBoardSilentUpdate = (id: number) =>
  useCallback(
    (payload: Partial<Omit<IBoard, 'id'>>) => {
      boardApiUpdate(id, payload);
    },
    [id],
  );

export const useBoardUiUpdate = () => useBoardStore(state => state.update);
