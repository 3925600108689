// eslint-disable-next-line import/no-extraneous-dependencies
import { stringifyUrl } from 'query-string';

import { API_URL } from '../api';
import type { AwsBucket } from '../model';

import { LS, LSKeys } from './localStorage';

export const getFileUrl = (path: string, bucket: AwsBucket) => {
  return stringifyUrl({
    url: `${API_URL}/get-file/${encodeURIComponent(path)}`,
    query: {
      token: LS.string(LSKeys.token),
      bucket: bucket.toString(),
    },
  });
};

export { stringifyUrl };

export function blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
