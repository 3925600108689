import type { Application, HookContext } from '@feathersjs/feathers';

import type { ServiceTypes } from '@board/server/src/declarations';
import type { IBoard } from '@board/shared/model';
import { serviceNames } from '@board/shared/serviceNames';

import { boardAction } from '../store';

export const connectOfflineProxy = (app: Application) => {
  const boardService = app.service(serviceNames.boards);
  boardService.hooks({
    before: {
      patch: [
        (
          context: HookContext<
            Partial<IBoard>,
            ServiceTypes[serviceNames.boards]
          >,
        ) => {
          const { id } = context;
          if (typeof id === 'number') {
            boardAction.update({
              id,
              ...context.data,
            });
          }
        },
      ],
    },
  });
};
