export enum errorTypes {
  boardsLimit = 'boardsLimit',
  planLimit = 'planLimit',
}

export interface IErrorBoardsLimit {
  type: errorTypes.boardsLimit;
  max: number;
  available: number;
}

export type IErrorSystem = IErrorBoardsLimit | { type: errorTypes.planLimit };
