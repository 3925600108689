// eslint-disable-next-line import/no-unresolved
import type { LibraryItems } from '@excalidraw/excalidraw/types/types';

export enum UserPrefKey {
  id = 'id',
  type = 'type',
  userId = 'userId',
  data = 'data',
}

export enum UserPrefType {
  Draw,
}

export interface UserPrefBase {
  id: number;
  type: UserPrefType;
  userId: number;
  data: unknown;
  createdAt: string;
  updatedAt: string;
}

interface IUserPrefGen<T extends UserPrefType, D> extends UserPrefBase {
  type: T;
  data: D;
}

export type IUserDrawPref = IUserPrefGen<
  UserPrefType.Draw,
  LibraryItems | null
>;

export type IUserPref = IUserDrawPref;
