import type React from 'react';
import type { PropsWithChildren } from 'react';

import makeStyles from '@mui/styles/makeStyles';

// copied to css add variable https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/CssBaseline/CssBaseline.js

const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      '--body-bg': theme.custom.bodyBg,
      backgroundColor: theme.custom.bodyBg,
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale', // Antialiasing.
      // Change from `box-sizing: content-box` so that `width`
      // is not affected by `padding` or `border`.
      boxSizing: 'border-box',
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    'strong, b': {
      fontWeight: theme.typography.fontWeightBold,
    },
    body: {
      margin: 0, // Remove the margin in all browsers.
      color: theme.palette.text.primary,
      ...theme.typography.body2,
      fontSize: theme.custom.board.fontSize ?? theme.typography.body2.fontSize,
      backgroundColor: 'var(--body-bg)',
      '@media print': {
        // Save printer ink.
        backgroundColor: theme.palette.common.white,
      },
      // Add support for document.body.requestFullScreen().
      // Other elements, if background transparent, are not supported.
      '&::backdrop': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
}));

/**
 * Kickstart an elegant, consistent, and simple baseline to build upon.
 */
export const CssBoardBaseline: React.FC<PropsWithChildren> = ({ children }) => {
  const classes = useStyles();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
