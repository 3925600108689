export enum extBookmarkKeys {
  id = 'id',
  tree = 'tree',
  userId = 'userId',
  browser = 'browser',
}

export enum ExtBrowsers {
  chrome = 'chrome',
  firefox = 'firefox',
}

export interface IExtBookmarkNode {
  title: string;
  id: string;
  url?: string;
  children?: IExtBookmarkNode[];
}

export interface IExtBookmarkDraft {
  tree: IExtBookmarkNode[];
  browser: ExtBrowsers;
}
export interface IExtBookmark extends IExtBookmarkDraft {
  id: number;
  userId: number;
}

export interface IExtUiBookmark extends Omit<IExtBookmarkNode, 'children'> {
  children?: string[];
  parent?: string;
}
