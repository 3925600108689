// eslint-disable-next-line import/no-extraneous-dependencies
import { captureException } from '@sentry/browser';

import {
  type ActionAttributes,
  attributes,
  create,
} from '../utils/ackee-tracker';
import { isCypress } from '../web';

const ackeeInstance = create('https://u.boardist.io', {
  detailed: true,
  ignoreLocalhost: true,
});

let allowTrack = true;
export const ackeeAllow = (allow: boolean) => {
  allowTrack = allow;
};

export const ackeeRecord = () => {
  if (!allowTrack || isCypress) return;
  try {
    ackeeInstance.record(
      process.env.REACT_APP_DOMAIN_ID as string,
      attributes(true),
    );
  } catch (e) {
    captureException(e, {
      level: 'error',
    });
  }
};

export const ackeeAction = (action: string, attrs?: ActionAttributes) => {
  if (!allowTrack || isCypress) return;
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    return;
  }
  try {
    ackeeInstance.action(action, attrs || { key: 'Click', value: 1 });
  } catch (e) {
    captureException(e, {
      level: 'error',
    });
  }
};
