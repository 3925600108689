/* eslint-disable no-param-reassign */
import { useCallback, useMemo } from 'react';

import { useKey } from 'hooks/keys';
import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';

import { BoardEvent, emitter } from '@board/basics/helpers/emitter';
import type { DialogKeyItem } from '@board/basics/helpers/keys';
import { trackDialog } from '@board/basics/helpers/track';
import { history } from '@board/basics/route';
import { getKeys, preventPropagationFn } from '@board/shared/utils';

import { devtools, immerMiddleware } from '../_shared_/zustand';

import { DialogKey } from './dialogKey';

export { DialogKey };
type State = {
  dialog: {
    [key in DialogKey]?: boolean;
  };
  show(key: DialogKey): void;
  hide(key: DialogKey): void;
  hideAll(): void;
};

export const useDialogStore = create<State>()(
  devtools(
    immerMiddleware(set => ({
      dialog: {},
      show: key =>
        set(state => {
          state.dialog[key] = true;
          trackDialog(key);
        }),
      hide: key =>
        set(state => {
          state.dialog[key] = false;
        }),
      hideAll: () =>
        set(state => {
          getKeys(state.dialog).forEach(key => {
            state.dialog[key] = false;
          });
        }),
    })),
    'dialog',
  ),
);

export const dialogAction = {
  show: (key: DialogKey) =>
    setTimeout(() => {
      unstable_batchedUpdates(() => {
        useDialogStore.getState().show(key);
      });
    }, 0),
  hide: (key: DialogKey) =>
    setTimeout(() => {
      unstable_batchedUpdates(() => {
        useDialogStore.getState().hide(key);
      });
    }, 0),
};

export const useDialog = (key: DialogKey) => {
  const open = useDialogStore(state => Boolean(state.dialog[key]));
  const show = useDialogStore(s => s.show);
  const hide = useDialogStore(s => s.hide);

  return useMemo(
    () => ({
      open,
      onOpen: () => show(key),
      onClose: () => hide(key),
    }),
    [key, open, show, hide],
  );
};

export const useKeyDialog = (keyAction: Required<DialogKeyItem>) => {
  const dialog = useDialog(keyAction.type);
  const action = useCallback(
    () => (dialog.open ? dialog.onClose() : dialog.onOpen()),
    [dialog.open],
  );

  const actionNoPropagation = useMemo(
    () => preventPropagationFn(action),
    [action],
  );

  useKey(keyAction.cmd, actionNoPropagation);
  return dialog;
};

// ----LISTENERS----//
let prevLocation = history.location;
history.listen(location => {
  // do not update for search change
  if (location.pathname !== prevLocation.pathname) {
    unstable_batchedUpdates(() => {
      useDialogStore.getState().hideAll();
    });
  }
  prevLocation = location;
});

emitter.on(BoardEvent.UrlsBlocked, () =>
  dialogAction.show(DialogKey.BrowserBlockedOpenUrl),
);
