import { useEffect } from 'react';

import mitt, { type Handler } from 'mitt';

export enum BoardEvent {
  WidgetHeight,
  AskWidgetHeight,
  BoardColor,
  UrlsBlocked,
  DevRerenderE,
}

type Events = {
  // emit height change, [id, height]
  [BoardEvent.WidgetHeight]: [string, number];
  [BoardEvent.AskWidgetHeight]: undefined;
  [BoardEvent.UrlsBlocked]: undefined;
  [BoardEvent.BoardColor]: undefined;
  [BoardEvent.DevRerenderE]: undefined;
};

export const emitter = mitt<Events>();

export const useEmitter = <Key extends keyof Events>(
  name: Key,
  callback: Handler<Events[Key]>,
) => {
  useEffect(() => {
    emitter.on(name, callback);
    return () => {
      emitter.off(name, callback);
    };
  }, [name, callback]);
};
