import type { Paginated } from '@feathersjs/feathers';

import type { DnDResult, DroppableTypes } from '../dnd';

import type { IBoard } from './board';
import type {
  IItemBookmark,
  IItemDoc,
  IItemFile,
  IItemTask,
  ItemAny,
} from './item';
import type { IShare } from './share';
import type { IWidget, widgetTypes } from './widget';

export enum timelineKeys {
  id = 'id',
  userId = 'userId',
  boardId = 'boardId',
  type = 'type',
  action = 'action',
  itemId = 'itemId',
  data = 'data',
  prev = 'prev',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum TimelineType {
  Task,
  Bookmark,
  File,
  Board,
  Widget,
  Document,
  WidgetClone,
  ItemMove,
  BoardShare,
}
export enum TimelineActionType {
  Add,
  Remove,
  Patch,
}

export interface Timeline {
  id: number;
  userId: number;
  itemId: number;
  boardId: number;
  type: TimelineType;
  action: TimelineActionType;
  createdAt: string;
  updatedAt: string;
}

export interface TimelineAdd<Type extends TimelineType, Item> extends Timeline {
  action: TimelineActionType.Add;
  type: Type;
  data: Item;
}

export interface TimelinePatch<Type extends TimelineType, Item>
  extends Timeline {
  action: TimelineActionType.Patch;
  type: Type;
  data: Item;
  prev: Item;
}

export interface TimelineRemove<Type extends TimelineType, Item>
  extends Timeline {
  action: TimelineActionType.Remove;
  type: Type;
  data: Item;
}

export type TimelineGeneric<Type extends TimelineType, Item> =
  | TimelineAdd<Type, Item>
  | TimelinePatch<Type, Item>
  | TimelineRemove<Type, Item>;

type OmitForCreate = 'id' | 'createdAt' | 'updatedAt' | 'boardId';

export type TimelineGenericCreate<Type extends TimelineType, Item> =
  | Omit<TimelineAdd<Type, Item>, OmitForCreate>
  | Omit<TimelinePatch<Type, Item>, OmitForCreate>
  | Omit<TimelineRemove<Type, Item>, OmitForCreate>;

export type ITimelineTask = TimelineGeneric<TimelineType.Task, IItemTask>;
export type ITimelineTaskCreate = TimelineGenericCreate<
  TimelineType.Task,
  IItemTask
>;

export type ITimelineBookmark = TimelineGeneric<
  TimelineType.Bookmark,
  IItemBookmark
>;
export type ITimelineBookmarkCreate = TimelineGenericCreate<
  TimelineType.Bookmark,
  IItemBookmark
>;

export type ITimelineWidget = TimelineGeneric<TimelineType.Widget, IWidget>;
export type ITimelineWidgetCreate = TimelineGenericCreate<
  TimelineType.Widget,
  IWidget
>;

export type ITimelineWidgetClone = TimelineGeneric<
  TimelineType.WidgetClone,
  IWidget
>;
export type ITimelineWidgetCloneCreate = TimelineGenericCreate<
  TimelineType.WidgetClone,
  IWidget
>;

export type ITimelineFile = TimelineGeneric<TimelineType.File, IItemFile>;
export type ITimelineFileCreate = TimelineGenericCreate<
  TimelineType.File,
  IItemFile
>;

export type ITimelineBoard = TimelineGeneric<TimelineType.Board, IBoard>;
export type ITimelineBoardCreate = TimelineGenericCreate<
  TimelineType.Board,
  IBoard
>;

export type ITimelineDocument = TimelineGeneric<
  TimelineType.Document,
  Omit<IItemDoc, 'data'>
>;
export type ITimelineDocumentCreate = TimelineGenericCreate<
  TimelineType.Document,
  Omit<IItemDoc, 'data'>
>;

export interface ItemMoveCommon {
  widgets: {
    [key: number]: {
      id: number;
      type: widgetTypes;
      title: string;
    };
  };
  item: ItemAny;
  dnd: Omit<DnDResult, 'type'> & { type: DroppableTypes.items };
}

export type ITimelineItemMove = TimelineGeneric<
  TimelineType.ItemMove,
  ItemMoveCommon
>;

export type ITimelineItemMoveCreate = TimelineGenericCreate<
  TimelineType.ItemMove,
  ItemMoveCommon
>;
export type ITimelineBoardShare = TimelineGeneric<
  TimelineType.BoardShare,
  IShare
>;

export type ITimelineBoardShareCreate = TimelineGenericCreate<
  TimelineType.BoardShare,
  IShare
>;

export type TimelineAny =
  | ITimelineTask
  | ITimelineBookmark
  | ITimelineFile
  | ITimelineBoard
  | ITimelineWidget
  | ITimelineWidgetClone
  | ITimelineItemMove
  | ITimelineDocument
  | ITimelineBoardShare;

export type TimelineAnyCreate =
  | ITimelineTaskCreate
  | ITimelineBookmarkCreate
  | ITimelineFileCreate
  | ITimelineBoardCreate
  | ITimelineWidgetCreate
  | ITimelineWidgetCloneCreate
  | ITimelineItemMoveCreate
  | ITimelineDocumentCreate
  | ITimelineBoardShareCreate;

export type TimelineCustomQuery =
  | { boardId: number }
  | {
      createdAt: {
        $lt: Date | string;
        $gt: Date | string;
      };
    }
  | {
      type: TimelineType;
      itemId: number;
    };

export type TimelineQuery = {
  $limit: number;
  $skip: number;
  $or?: any;
} & TimelineCustomQuery;

export type TimelineFind<T = TimelineAny> = Paginated<T>;
