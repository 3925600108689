import { useCallback, useEffect } from 'react';

import {
  type ApiContextValue,
  useApiContext,
} from '@board/basics/useApiContext';
import { useAsyncFnWithLoader } from '@board/hooks/useLoader';
import { featherEventEmitters } from '@board/shared/featherTypes';
import type { WidgetFavorite, WidgetFavoriteDraft } from '@board/shared/model';
import { serviceNames } from '@board/shared/serviceNames';

import { favoriteWidgetAction } from './store';

const createEffect = (fav: WidgetFavorite | WidgetFavorite[]) =>
  favoriteWidgetAction.set(fav);

export const favoriteWidgetSetupListeners = (app: ApiContextValue) => {
  const service = app.service(serviceNames.widgetsFavorite);
  service.on(featherEventEmitters.create, createEffect);
  service.on(featherEventEmitters.patch, createEffect);
  service.on(featherEventEmitters.remove, removeEffect);
};

export const useFavWidgetCreate = () => {
  const app = useApiContext();
  const onCreate = useCallback(
    (payload: WidgetFavoriteDraft) =>
      app.service(serviceNames.widgetsFavorite).create(payload),
    [app],
  );
  return useAsyncFnWithLoader(onCreate);
};

export const useFavWidgetUpdate = (id: number) => {
  const app = useApiContext();

  return useCallback(
    (payload: Partial<Omit<WidgetFavorite, 'id'>>) => {
      app
        .service(serviceNames.widgetsFavorite)
        .patch(id, payload)
        .then(createEffect);
    },
    [id, app],
  );
};

const removeEffect = (fav: WidgetFavorite) => favoriteWidgetAction.remove(fav);

export const useFavWidgetRemove = () => {
  const app = useApiContext();
  const onRemove = useCallback(
    (id: number) => app.service(serviceNames.widgetsFavorite).remove(id),
    [app],
  );
  return useAsyncFnWithLoader(onRemove);
};

export const useInitFavoriteWidgets = () => {
  const app = useApiContext();
  useEffect(() => {
    app
      .service(serviceNames.widgetsFavorite)
      .find()
      .then((fav: WidgetFavorite[]) => createEffect(fav));
  }, [app]);
};
