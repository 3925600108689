export enum SubscribeType {
  Timeline,
  Comments,
  Boards,
}

export enum SubscribeEvent {
  Connect,
  Disconnect,
}

export type ISubscribeCreate =
  | {
      id: number;
      type: SubscribeType.Comments;
    }
  | { type: SubscribeType.Timeline }
  | { type: SubscribeType.Boards; ids: number[] };

export type ISubscribe = {
  event: SubscribeEvent;
  data: ISubscribeCreate;
};
