import { darken, getContrastRatio, lighten } from '@mui/material';
import { amber, green } from '@mui/material/colors';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import { dark } from '@mui/material/styles/createPalette';
import muiMakeStyles from '@mui/styles/makeStyles';

import { setDocumentBg } from '../web';

import { decomposeToRgba, mainColors, mainColorsDark } from './color';

const fontSize = 14; // material default
const htmlFontSize = 16; // browser default
const coef = fontSize / 14;
const contrastThreshold = 3;

function pxToRem(value: number) {
  return `${(value / htmlFontSize) * coef}rem`;
}

const getPrimaryColor = (isDark: boolean) =>
  isDark ? mainColorsDark : mainColors;

export const makeStyles = muiMakeStyles;

const createAppTheme = ({
  color,
  isDark: inputIsDark,
  board,
}: {
  color: string;
  isDark?: boolean;
  board?: {
    fontSize?: number;
  };
}) => {
  const isDark = inputIsDark ?? isDarkColor(color);

  return createTheme({
    colors: getPrimaryColor(isDark),
    isDark,
    withPaperBg: color,
  });
};
export const createDefaultTheme = (isDark: boolean) => {
  return createTheme({
    colors: getPrimaryColor(isDark),
    isDark,
  });
};

export const isDarkColor = (color: string) =>
  getContrastRatio(color, dark.text.primary) >= contrastThreshold;

export const createCustomTheme = (color: string, paperBg = false) => {
  const isDark = isDarkColor(color);

  return createTheme({
    colors: getPrimaryColor(isDark),
    isDark,
    withPaperBg: color,
  });
};

const getPaperBg = (color: string, isDark: boolean) => {
  return isDark ? lighten(color, 0.1) : darken(color, 0.05);
};
type CreateThemeOptions = {
  colors: { primary: string; secondary: string };
  isDark: boolean;
  withPaperBg?: string;
  bodyFontSize?: number;
};
export const createTheme = ({
  colors,
  isDark,
  withPaperBg,
  bodyFontSize,
}: CreateThemeOptions) => {
  const paletteBg = isDark ? mainColorsDark.background : mainColors.background;
  const paperBg = withPaperBg
    ? getPaperBg(withPaperBg, isDark)
    : paletteBg.paper;

  const bodyBg = withPaperBg || paletteBg.default;

  setDocumentBg(paperBg);
  const custom = {
    line: `1px solid rgba(${isDark ? '255, 255, 255' : '0, 0, 0'}, 0.12)`,
    success: {
      main: green[700],
    },
    lightBg: decomposeToRgba(colors.primary, isDark ? 0.1 : 0.05),
    landing: {
      primaryText: isDark ? '#d25b5b' : '#212353',
      secondaryText: isDark ? '#bbd8e8' : '#4B5D68',
    },
    warn: {
      light: amber[300],
      main: amber[500],
    },
    bodyBg,
    paperBg,
    board: {
      fontSize: bodyFontSize,
    },
  };
  return createMuiTheme({
    custom,
    palette: {
      mode: isDark ? 'dark' : 'light',
      primary: {
        light: lighten(colors.primary, 0.2),
        main: colors.primary,
        dark: darken(colors.primary, 0.2),
      },
      secondary: {
        light: lighten(colors.secondary, 0.2),
        // main: isDark ? lighten(colors.secondary, 0.4) : colors.secondary,
        main: colors.secondary,
        dark: darken(colors.secondary, 0.2),
      },
      background: paletteBg,
      // ...(paperBg ? getPaperBg(colors.primary, isDark) : {}),
    },
    components: {
      MuiCheckbox: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // backgroundColor: paperBg,
            backgroundImage: 'none',
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          textColor: 'inherit',
          indicatorColor: 'secondary',
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow:
              '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: isDark
              ? lighten(paperBg, 0.2)
              : darken(paperBg, 0.2),
            // backdropFilter,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
    },
    typography: {
      // fontFamily: "'Open Sans', sans-serif",
      h4: {
        // color: 'rgba(0, 0, 0, 0.54)',
        fontSize: pxToRem(21),
      },
      body1: {
        wordBreak: 'break-word',
        wordWrap: 'break-word',
      },
      body2: {
        wordBreak: 'break-word',
        wordWrap: 'break-word',
      },
    },
  });
};

// KEEP ALIGNED in typings/global.d.ts
// type Custom = {
//   line: string;
//   lightBg: string;
//   success: { main: string };
//   landing: {
//     primaryText: string;
//     secondaryText: string;
//   };
//   warn: {
//     light: string;
//     main: string;
//   };
//   bodyBg: string;
//   paperBg: string;
//   board: {
//     fontSize?: number;
//   };
// };
//
// declare module '@mui/material/styles' {
//   interface Theme {
//     custom: Custom;
//   }
//   interface ThemeOptions {
//     custom?: PartialDeep<Custom>;
//   }
// }
