import { createBrowserHistory, createMemoryHistory, parsePath } from 'history';

import { getLink } from '@board/shared/links';
import { LS, LSKeys, isStandaloneWebApp } from '@board/shared/web';

const isMemoryHistory = isStandaloneWebApp;

const getInMemory = () => {
  const lastKey = LS.string(LSKeys.lastVisited);
  if (lastKey) {
    return createMemoryHistory({
      initialEntries: [lastKey],
    });
  }
  return createMemoryHistory();
};

export const history = isMemoryHistory ? getInMemory() : createBrowserHistory();

if (isMemoryHistory) {
  history.listen(route => {
    LS.setStr(LS.keys.lastVisited, route.pathname);
  });
}

const route = history.push;

export { parsePath, isMemoryHistory };

type Id = number | string;
export const goTo = {
  route,
  back: history.goBack,
  board: (id: Id) => {
    route(getLink.board(id));
  },
  docModal: (id: Id) => route(getLink.docModal(id)),
  task: (id: Id) => route(getLink.task(id)),
};
