import type React from 'react';

import { Grid, type GridProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  grid: {
    height: '100%',
  },
});

export const BodyCenter: React.FC<GridProps> = props => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      className={classes.grid}
      container
      justifyContent="center"
      {...props}
    />
  );
};
