import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { FeathersError } from '@feathersjs/errors';

import { onRequestError } from '@board/basics/alert';
import { getApiAsApp } from '@board/basics/api';
import type { serviceNames } from '@board/shared/serviceNames';

import { useDebounce, useThrottle } from './useDebouncedFn';
import useDeepCompare from './useDeepCompare';
import { useIsMounted } from './useIsMounted';

type Query = {
  [key: string]: any;
};

export const feathersFind = <G, ReqQuery = Query>(
  serviceName: serviceNames,
  query: ReqQuery,
): Promise<G> =>
  getApiAsApp()
    .service(serviceName)
    .find({ query })
    .catch((e: unknown) => onRequestError(e));

export const useFeathersFind = <G, ReqQuery = Query>(
  serviceName: serviceNames,
  query: ReqQuery,
  defaultValue: G | null = null,
  active: boolean | number = true,
): [G | null, boolean, Error | null] => {
  const isMounted = useIsMounted();
  const [data, setData] = useState<G | null>(defaultValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FeathersError | null>(null);

  useEffect(() => {
    setError(null);
    if (!active) {
      setData(defaultValue);
      return undefined;
    }
    setLoading(true);
    const runFind = async () => {
      try {
        const result = await getApiAsApp().service(serviceName).find({ query });
        if (isMounted.current) {
          setData(result);
        }
      } catch (e) {
        if (!isMounted.current) {
          return;
        }
        if (e instanceof FeathersError) {
          setError(e);
        }
        onRequestError(e);
      }
      setLoading(false);
    };
    runFind();
    return undefined;
  }, [serviceName, useDeepCompare(query), active]);
  return [data, loading, error];
};

export const useFeathersThrottledFind = <G, ReqQuery = Query>(
  serviceName: serviceNames,
  query: ReqQuery,
  defaultValue: G | null = null,
  active: boolean | number = true,
): [G | null, boolean, Error | null] => {
  const isMounted = useIsMounted();
  const [data, setData] = useState<G | null>(defaultValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FeathersError | null>(null);

  const throttledFind = useDebounce(
    () => {
      const runFind = async () => {
        try {
          const result = await getApiAsApp()
            .service(serviceName)
            .find({ query });
          if (isMounted.current) {
            setData(result);
          }
        } catch (e) {
          if (!isMounted.current) {
            return;
          }
          if (e instanceof FeathersError) {
            setError(e);
          }
          onRequestError(e);
        }
        setLoading(false);
      };
      runFind();
    },
    250,
    4000,
  );

  useEffect(() => {
    setError(null);
    if (!active) {
      setData(defaultValue);
      return undefined;
    }
    setLoading(true);
    throttledFind();
    return undefined;
  }, [serviceName, useDeepCompare(query), active, throttledFind]);
  return [data, loading, error];
};
