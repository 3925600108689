import { type DependencyList, useCallback, useState } from 'react';

import { onRequestError } from '@board/basics/alert';
import { getApiAsApp } from '@board/basics/api';
import type { serviceNames } from '@board/shared/serviceNames';

import {
  LoaderAction,
  useAsyncCallbackWithLoader,
  useAsyncFnWithLoader,
  useLoader,
} from './useLoader';

export const useFeathersCreate = <Payload, Response>(
  serviceName: serviceNames,
  deps: DependencyList = [],
) => {
  // todo deprecate hook - getApiAsApp
  const service = getApiAsApp().service(serviceName);
  const [loader, setLoader] = useLoader();
  const [data, setData] = useState<Response | null>(null);

  const onCreate = useCallback(
    (
      payload: Payload,
      params?: {
        [key: string]: any;
      },
    ) => {
      setLoader(LoaderAction.Pending);
      service
        .create(payload, params)
        .then((response: Response) => {
          setData(response);
          setLoader(LoaderAction.Success);
        })
        .catch((e: unknown) => {
          onRequestError(e);
          setLoader(LoaderAction.Error);
        });
    },
    deps,
  );
  return [onCreate, loader, data] as const;
};
export const useFeathersCreateCallback = <Payload, Response>(
  serviceName: serviceNames,
  callback: (data: Response) => void,
) => {
  return useAsyncCallbackWithLoader<Payload, Response>(
    payload => getApiAsApp().service(serviceName).create(payload),
    callback,
  );
};

export const useFeathersUpdate = <Payload>(
  serviceName: serviceNames,
  id: number,
) => {
  const service = getApiAsApp().service(serviceName);

  const [loader, setLoader] = useLoader();

  const onPatch = useCallback(
    (payload: Partial<Omit<Payload, 'id'>>) => {
      setLoader(LoaderAction.Pending);

      service
        .patch(id, payload)
        .then(() => {
          setLoader(LoaderAction.Success);
        })
        .catch((e: unknown) => {
          onRequestError(e);
          setLoader(LoaderAction.Error);
        });
    },
    [id],
  );
  return [onPatch, loader] as const;
};

export const useFeathersRemove = (serviceName: serviceNames, id: number) => {
  const [onRemoveCb, loader] = useFeathersRemoveCallback(serviceName);
  const onRemove = useCallback(() => onRemoveCb(id), [id]);
  return [onRemove, loader] as const;
};

export const useFeathersRemoveCallback = (serviceName: serviceNames) => {
  const service = getApiAsApp().service(serviceName);

  const onRemove = useCallback((id: number | number[]) => {
    return service.remove(Array.isArray(id) ? null : id, {
      query: {
        id: Array.isArray(id) ? { $in: id } : undefined,
      },
    });
  }, []);

  return useAsyncFnWithLoader(onRemove);
};
