import { gbToByte, mbToByte } from '../utils/disk';

import type { AwsRegion } from './aws';

export enum userKeys {
  id = 'id',
  name = 'name',
  email = 'email',
  onboarding = 'onboarding',
  inPrivate = 'inPrivate',
  submitKey = 'submitKey',
  startWeek = 'startWeek',
  password = 'password',
  showWidgetIcon = 'showWidgetIcon',
  plan = 'plan',
  planStatus = 'planStatus',
  isAdmin = 'isAdmin',
  noTrack = 'noTrack',
  config = 'config',
}

export enum planKeys {
  free = 1,
  beta = 2,
  unlimited = 3,
}

export enum PlanStatus {
  trial = 1,
  expired,
  paid,
  paidExpired,
  free,
}

export enum planSpecials {
  unlimited = 'unlimited',
}
export const planNames: { [key in planKeys]: string } = {
  [planKeys.free]: 'Basic',
  [planKeys.beta]: 'Co-Active',
  [planKeys.unlimited]: 'Pro',
};

export enum SubmitKeys {
  enter = '⏎',
  cmdEnter = '⌘+⏎',
}
export enum UserMenuView {
  Inline = 1,
  Popover,
}

enum UserConfigKey {
  BoardFontSizeRangeMobile,
  BoardFontSizeRangeDesktop,
}
export interface IUser {
  id: number;
  name: string;
  email: string;
  onboarding: boolean;
  inPrivate: boolean;
  showWidgetIcon: boolean;
  submitKey: SubmitKeys;
  startWeek: 'Monday' | 'Sunday';
  plan: planKeys;
  planStatus: PlanStatus | null;
  noTrack: boolean;
  config: {
    bucket?: AwsRegion;
    confirmed?: boolean;
    promo?: boolean;
    darkTheme?: boolean;
    taskPanelDay?: string;
    menu?: UserMenuView;
    noReferrer?: boolean;
    hideItemAlert?: boolean;
    [UserConfigKey.BoardFontSizeRangeDesktop]?: number;
    [UserConfigKey.BoardFontSizeRangeMobile]?: number;
  };
}

export const MAX_FILE_SIZE = mbToByte(8);

type planInfo = {
  DISK: number;
  BOARDS: number;
  MAX_FILE_SIZE: number;
  History: number | planSpecials.unlimited;
};

export const PLANS: { [key in planKeys]: planInfo } = {
  [planKeys.free]: {
    DISK: mbToByte(100),
    BOARDS: 5,
    MAX_FILE_SIZE: mbToByte(5),
    History: 0,
  },
  [planKeys.beta]: {
    DISK: gbToByte(2),
    BOARDS: 5,
    MAX_FILE_SIZE: mbToByte(80),
    History: planSpecials.unlimited,
  },
  [planKeys.unlimited]: {
    BOARDS: 50,
    DISK: gbToByte(8),
    MAX_FILE_SIZE: mbToByte(80),
    History: planSpecials.unlimited,
  },
};

export const freePlan = PLANS[planKeys.free];

export interface AuthPayload {
  accessToken: string;
  payload?: {
    aud: string;
    exp: number;
    iat: number;
    sub: string;
  };
  strategy: string;
}
