export enum progressKeys {
  id = 'id',
  active = 'active',
  hold = 'hold',
  start = 'start',
  end = 'end',
  taskId = 'taskId',
  userId = 'userId',
  prolong = 'prolong',
}

export enum progressEvents {
  start = 'start',
  stop = 'stop',
  hold = 'hold',
  resume = 'resume',
  prolong = 'prolong',
}

export interface IProgressUpdate {
  type: progressEvents;
  id: number;
  prolong?: number;
}

export interface IProgressDraft {
  start: Date;
  taskId: number;
  userId: number;
}

export interface IProgress extends IProgressDraft {
  id: number;
  active: boolean;
  hold: boolean;
  end: Date;
  prolong: number;
}
