type ReactAnyEvent =
  | React.MouseEvent
  | React.ChangeEvent
  | React.KeyboardEvent
  | KeyboardEvent;
export const preventPropagation = (e: ReactAnyEvent) => {
  e.preventDefault();
  e.stopPropagation();
};
export const stopPropagation = (e: ReactAnyEvent) => e.stopPropagation();

export const preventPropagationFn =
  <Event extends ReactAnyEvent>(fn: (e: Event) => any) =>
  (e: Event) => {
    preventPropagation(e);
    fn(e);
  };
export const nativePreventPropagation = (e: KeyboardEvent) => {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
};
