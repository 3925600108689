import { useEffect } from 'react';

import type { ILoader } from '@board/shared/redux/loader';

import { usePrevious } from './usePrevious';

export const useOnSuccess = (loader: ILoader, onSuccess: () => void) => {
  const wasPending = usePrevious(loader.pending);
  useEffect(() => {
    if (loader.success && wasPending) {
      onSuccess();
    }
  }, [loader.success]);
};

export const useOnError = (loader: ILoader, onError: () => void) => {
  const wasPending = usePrevious(loader.pending);
  useEffect(() => {
    if (loader.error && wasPending) {
      onError();
    }
  }, [loader.error]);
};
