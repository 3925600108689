export const isUndefined = (x: any): x is undefined => typeof x === 'undefined';

export const isString = (value: any): value is string =>
  typeof value === 'string';

export const isNumber = (value: any): value is number =>
  typeof value === 'number';

export function isNotString<T>(value: T): value is Exclude<T, string> {
  return typeof value !== 'string';
}

type AnyFunction = (...args: any[]) => any;

export const isFunction = (value: any): value is AnyFunction =>
  typeof value === 'function';

export const shouldBeNum = (value: string | number): number =>
  isString(value) ? Number.parseFloat(value) : value;

export function isHTMLElement(x: unknown): x is HTMLElement {
  return x instanceof HTMLElement;
}
export function isSVGElement(x: unknown): x is HTMLElement {
  return x instanceof SVGElement;
}
export function nullthrows<T>(input?: T | null, msg?: string): T {
  if (input == null) {
    throw new Error(msg);
  }
  return input;
}
