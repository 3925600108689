/* eslint-disable no-param-reassign */
import type {
  IExtBookmark,
  IExtBookmarkNode,
  IExtUiBookmark,
} from '@board/shared/model/extBookmark';

type BookmarksData = { [key: string]: IExtUiBookmark };
const normalizeItem = (
  item: IExtBookmarkNode,
  obj: BookmarksData,
  parent?: string,
): string => {
  obj[item.id] = {
    ...item,
    parent,
    children: Array.isArray(item.children)
      ? item.children.map(i => normalizeItem(i, obj, item.id))
      : undefined,
  };
  return item.id;
};

export const normalizeBookmarks = (data: IExtBookmark) => {
  const bookmarks: BookmarksData = {};

  const list = Array.isArray(data.tree)
    ? data.tree.map(item => normalizeItem(item, bookmarks))
    : [];
  return { list, bookmarks };
};
