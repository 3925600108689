import React from 'react';
import './index.css';

import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';

import { setApi } from '@board/basics/api';
import { inOfflineProxyApi } from '@board/basics/api/inOfflineProxyApi';
import { inServerApi } from '@board/basics/api/inServerApi';
import { isString, nullthrows } from '@board/shared/utils';

import AppContainer from './AppContainer';

if (process.env.NODE_ENV === 'development') {
  setApi(inOfflineProxyApi);
} else {
  setApi(inServerApi);
}
// if (process.env.NODE_ENV !== 'production') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React);
// }

if (process.env.IS_VITE) {
  window.global = window;
}

Sentry.init({
  release: process.env.REACT_APP_VERSION,
  dsn: 'https://f2806cca505b4b098fb913beee8a40f8@sentry.io/1533920',
  ignoreErrors: [
    'ResizeObserver loop',
    'Timeout of 5000ms exceeded calling create on authentication',
    'Timeout of 5000ms exceeded calling create on notification',
  ],
});

const portal = document.createElement('div');
portal.setAttribute('id', 'portal');
document.body.appendChild(portal);

const root = createRoot(nullthrows(document.getElementById('root')));
root.render(<AppContainer />);

window.addEventListener(
  'error',
  e => {
    // fix react beautiful dnd fail
    if (isString(e.message) && e.message.startsWith('ResizeObserver loop')) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  },
  true,
);
