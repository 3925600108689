import React, { createContext, useContext } from 'react';

import invariant from 'invariant';

import type { ApiServices, ItemsService } from '@board/shared/api';
import type { ItemAny } from '@board/shared/model';
import { serviceNames } from '@board/shared/serviceNames';

export const ApiContext = createContext<ApiContextValue>(
  null as unknown as ApiContextValue,
);

export const useApiContext = () => {
  const api = useContext(ApiContext);
  invariant(api, 'API required');
  return api;
};
export const useItemsService = <T extends ItemAny>() =>
  useApiContext().service(serviceNames.items) as ItemsService<T>;
export const useWidgetsService = () =>
  useApiContext().service(serviceNames.widgets);
export const useUsersService = () =>
  useApiContext().service(serviceNames.users);
export const useBoardsService = () =>
  useApiContext().service(serviceNames.boards);
export const useWidgetActionsService = () =>
  useApiContext().service(serviceNames.widgetActions);
export const usePrettifyService = () =>
  useApiContext().service(serviceNames.prettify);

export interface ApiContextValue {
  service<T extends keyof ApiServices>(key: T): ApiServices[T];
  /**
   * @deprecated only for internal use
   */
  userId: number;
}
export type { ItemsService };
