/* eslint-disable no-param-reassign */
import { create } from 'zustand';

import { listSelector, simpleMemo } from '@board/basics/helpers/selectors';
import {
  ExtBrowsers,
  type IExtBookmark,
  type IExtUiBookmark,
} from '@board/shared/model';
import { assign } from '@board/shared/utils';

import { immerMiddleware } from '../_shared_/zustand';

import { normalizeBookmarks } from './utils';

type State = {
  init: boolean;
  [ExtBrowsers.chrome]: string[];
  [ExtBrowsers.firefox]: string[];
  bookmarks: {
    [key: string]: IExtUiBookmark;
  };
  set(payload: IExtBookmark[]): void;
  patch(payload: IExtBookmark): void;
};

export const useExtBookmarkStore = create<State>()(
  immerMiddleware(set => ({
    init: false,
    [ExtBrowsers.firefox]: [],
    [ExtBrowsers.chrome]: [],
    bookmarks: {},
    set: payload =>
      set(state => {
        const chrome = payload.find(i => i.browser === ExtBrowsers.chrome);
        if (chrome) {
          const { list, bookmarks } = normalizeBookmarks(chrome);
          state[ExtBrowsers.chrome] = list;
          state.bookmarks = assign(state.bookmarks, bookmarks);
        }
        const firefox = payload.find(i => i.browser === ExtBrowsers.firefox);
        if (firefox) {
          const { list, bookmarks } = normalizeBookmarks(firefox);
          state[ExtBrowsers.firefox] = list;
          state.bookmarks = assign(state.bookmarks, bookmarks);
        }
        state.init = true;
      }),
    patch: payload =>
      set(state => {
        const { list, bookmarks } = normalizeBookmarks(payload);
        state[payload.browser] = list;
        state.bookmarks = assign(state.bookmarks, bookmarks);
      }),
  })),
);

export const extBookmarkSelector = {
  bookmarks: (state: State) => state.bookmarks,
  firefox: (state: State) => state[ExtBrowsers.firefox],
  chrome: (state: State) => state[ExtBrowsers.chrome],
  list: (state: State) => {
    const list = [...state.firefox, ...state.chrome];
    return simpleMemo('extList', list);
  },
  get: (id: string) => (state: State) => state.bookmarks[id],
  children: (id: string) => (state: State) => {
    const item = extBookmarkSelector.get(id)(state);
    if (item && item.children) {
      return listSelector(extBookmarkSelector.bookmarks(state), {
        id: `ext-bookmarks:${id}`,
        list: item.children,
      });
    }
    return undefined;
  },
};
