import type { FeedbackIteration } from './feedback';

export interface IFeedbackMail {
  id: number;
  userId: number;
  open: boolean;
  iteration: FeedbackIteration;
}

export enum feedbackMailKeys {
  id = 'id',
  userId = 'userId',
  open = 'open',
  iteration = 'iteration',
}
