import type { FeathersError } from '@feathersjs/errors';
import type { HttpError } from 'http-errors';

import type { IErrorSystem } from '@board/shared/errorTypes';
import { isObjectLike, isString } from '@board/shared/utils';
import { LS, LSKeys } from '@board/shared/web';

import { systemMessage } from './messages';
import type { Alert, RequestError } from './types';

const isFeathersError = (e: HttpError | FeathersError): e is FeathersError =>
  'type' in e && e.type === 'FeathersError';

const isHttpError = (e: HttpError | FeathersError): e is HttpError =>
  e instanceof Error;

export const isHandledError = (e: unknown): e is FeathersError | HttpError => {
  if (e && isFeathersError(e as HttpError | FeathersError)) {
    return true;
  }
  if (e && isHttpError(e as HttpError | FeathersError)) {
    return true;
  }
  return false;
};
export const isUnAuthed = (e: HttpError | FeathersError) => {
  if (isFeathersError(e) && e.code === 401) {
    const { location } = window;
    if (location.pathname === '/login') {
      return false;
    }
    LS.set({
      [LSKeys.backUrl]: location.pathname + location.search,
    });
    return true;
  }
  return false;
};

export const chooseErrorMessage = (e: RequestError): Alert | null => {
  if (isFeathersError(e)) {
    const { code, name, message, data = {} } = e;

    if (code === 403) {
      if (isObjectLike(data) && 'type' in data) {
        return systemMessage(data as IErrorSystem, message);
      }
    }
    // don't show timeout messages
    if (code === 408) {
      return null;
    }
    return {
      msg: `${name}: ${message}`,
    };
  }
  const { message, status, code } = e as HttpError;

  if (isString(message)) {
    return { msg: message };
  }

  if (status === 413) {
    return {
      msg: 'File too large',
    };
  }

  return { msg: 'Sorry, something went wrong' };
};
