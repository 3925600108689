import React, { useCallback } from 'react';

import {
  type Alert,
  AlertType,
  type RequestError,
  alertAction,
  onRequestError,
} from '@board/basics/alert';
import { TrackKey, trackKey } from '@board/basics/helpers/track';
import { goTo } from '@board/basics/route';
import {
  type ApiContextValue,
  useApiContext,
} from '@board/basics/useApiContext';
import { widgetInfo } from '@board/basics/widget/info';
import { useAsyncFnWithLoader } from '@board/hooks/useLoader';
import { featherEventEmitters } from '@board/shared/featherTypes';
import {
  type ICloneWidget,
  type ICloneWidgetRes,
  type IMoveWidget,
  type IMoveWidgetRes,
  type IWidget,
  type IWidgetActionRes,
  type IWidgetAny,
  type IWidgetCreateRes,
  type IWidgetCreateUI,
  type IWidgetRemoveRes,
  WidgetApiAction,
  WidgetCreateType,
  isBoardUnArchived,
} from '@board/shared/model';
import { serviceNames } from '@board/shared/serviceNames';
import { Trans } from '@board/ui/Lang';

import { boardAction } from '../boardStore';

import { useWidgetStore, widgetAction } from './store';

const createEffect = ({ widgets, board }: IWidgetCreateRes) => {
  // do not store archived
  widgets.forEach(widget => {
    if (!widget.archived) {
      widgetAction.storeSet(widget);
      if (!widget.config?.child && board) {
        boardAction.update(board, { addW: widget.id });
      }
    }
  });
};

export const widgetStoreSetupListeners = (app: ApiContextValue) => {
  const widgetService = app.service(serviceNames.widgets);
  widgetService.on(featherEventEmitters.create, createEffect);
  widgetService.on(featherEventEmitters.remove, removeEffect);
  widgetService.on(featherEventEmitters.patch, updateEffect);

  app
    .service(serviceNames.widgetMove)
    .on(featherEventEmitters.create, moveEffect);

  app
    .service(serviceNames.widgetActions)
    .on(featherEventEmitters.create, actionsEffect);

  app
    .service(serviceNames.widgetClone)
    .on(featherEventEmitters.create, cloneEffect);
};

const create = (
  app: ApiContextValue,
  payload: IWidgetCreateUI,
): Promise<readonly [IWidgetCreateRes, Alert]> =>
  app
    .service(serviceNames.widgets)
    .create(payload)
    .then(data => {
      createEffect(data);
      const [widget] = data.widgets;
      let alert: Alert;
      if (widget == null) {
        alert = {
          msg: 'Widget is not created. Try again',
          replaceType: AlertType.Error,
        };
      } else {
        alert = {
          msg: <Trans>{widgetInfo.getName(widget.type)} created</Trans>,
          action: () => remove(app, widget.id),
        };
      }
      return [data, alert] as const;
    })
    .catch(e => {
      // onRequestError(e);
      console.error(e);
      throw new Error(e);
    });

export const useWidgetCreate = () => {
  const app = useApiContext();
  const onCreate = useCallback(
    (payload: IWidgetCreateUI) => {
      return create(app, payload).then(([data, alert]) => {
        if (alert) {
          alertAction.success(alert);
        }
        return data;
      });
    },
    [app],
  );
  return useAsyncFnWithLoader(onCreate);
};

const removeEffect = ({ board, widgets, updateWidget }: IWidgetRemoveRes) => {
  widgets.forEach(widget => {
    if (!widget.config.child && isBoardUnArchived(board)) {
      boardAction.update(board, { removeW: widget.id });
    }
  });
  if (updateWidget) {
    widgetAction.storeSet(updateWidget);
  }
  widgetAction.storeRemove(widgets);
};

const remove = (
  app: ApiContextValue,
  id: number,
  comboParent?: number,
): Promise<[IWidgetRemoveRes, Alert]> =>
  app
    .service(serviceNames.widgets)
    .remove(id, { query: { comboParent } })
    .then(data => {
      const [widget] = data.widgets;
      let alert: Alert;
      if (widget == null) {
        alert = {
          msg: 'Widget is not removed. Try again',
          replaceType: AlertType.Error,
        };
      } else {
        alert = {
          msg: <Trans>{widgetInfo.getName(widget.type)} removed</Trans>,
          action() {
            const { id, userId, ...widgetRest } = widget;
            return create(app, {
              ...widgetRest,
              createType: WidgetCreateType.Insert,
            });
          },
        };
      }

      removeEffect(data);
      return [data, alert] as [IWidgetRemoveRes, Alert];
    });

export const useWidgetRemove = (comboParent?: number) => {
  const app = useApiContext();
  const onRemove = useCallback(
    (id: number) => {
      return remove(app, id, comboParent).then(([data, alert]) => {
        alertAction.success(alert);
        trackKey(TrackKey.WidgetDelete);
        return data;
      });
    },
    [app, comboParent],
  );
  return useAsyncFnWithLoader(onRemove);
};

const updateEffect = (widget: IWidget) => widgetAction.storeSet(widget);

const widgetApiUpdate = (
  app: ApiContextValue,
  id: number,
  payload: Partial<Omit<IWidget, 'id'>>,
) =>
  app
    .service(serviceNames.widgets)
    .patch(id, payload)
    .then(updateEffect)
    .catch((e: RequestError) => {
      onRequestError(e);
    });

export const useWidgetSilentUpdate = <T extends IWidgetAny>(id: number) => {
  const app = useApiContext();

  return useCallback(
    (payload: Partial<Omit<T, 'id'>>) => {
      return widgetApiUpdate(app, id, payload);
    },
    [id, app],
  );
};

const cloneEffect = ({ result, board }: ICloneWidgetRes) => {
  widgetAction.storeSet(result);
  boardAction.update(board, { addW: result.id });
};

export const useWidgetClone = () => {
  const app = useApiContext();
  const onClone = useCallback(
    (payload: ICloneWidget) => {
      return app
        .service(serviceNames.widgetClone)
        .create(payload)
        .then(data => {
          alertAction.success({
            msg: <Trans>Widget {data.result.title || ''} cloned</Trans>,
            action: () => remove(app, data.result.id),
          });
          cloneEffect(data);
        });
    },
    [app],
  );
  return useAsyncFnWithLoader(onClone);
};

const moveEffect = ({ fromBoard, toBoard, widget }: IMoveWidgetRes) => {
  boardAction.update(fromBoard, { removeW: widget.id });
  boardAction.update(toBoard, { addW: widget.id });
  widgetAction.storeSet(widget);
};

const move = (
  app: ApiContextValue,
  payload: IMoveWidget,
  moveBack?: boolean,
): Promise<[IMoveWidgetRes, Alert]> =>
  app
    .service(serviceNames.widgetMove)
    .create(payload)
    .then((data: IMoveWidgetRes) => {
      const alert: Alert = {
        msg: moveBack ? (
          <Trans>Widget moved back</Trans>
        ) : (
          <Trans>Widget moved</Trans>
        ),
        action: () =>
          move(
            app,
            {
              ...payload,
              boardId: payload.toBoard,
              toBoard: payload.boardId,
            },
            !moveBack,
          ),
        second: {
          name: 'Visit',
          action: () => goTo.board(data.toBoard.id),
        },
      };
      moveEffect(data);
      return [data, alert] as [IMoveWidgetRes, Alert];
    });

export const useWidgetMove = () => {
  const app = useApiContext();
  const onMove = useCallback(
    (payload: IMoveWidget) => {
      return move(app, payload).then(([data, alert]) => {
        alertAction.success(alert);
      });
    },
    [app],
  );
  return useAsyncFnWithLoader(onMove);
};

const actionsEffect = (response: IWidgetActionRes) => {
  if (response) {
    const { board, type, widget } = response;
    boardAction.update(board, {
      addW: type === WidgetApiAction.Restore ? widget.id : undefined,
      removeW: type === WidgetApiAction.Archive ? widget.id : undefined,
    });
    useWidgetStore.getState().set(widget);
  }
};

const archive = (
  app: ApiContextValue,
  id: number,
): Promise<[IWidgetActionRes, Alert]> =>
  app
    .service(serviceNames.widgetActions)
    .create({ id, type: WidgetApiAction.Archive })
    .then((data: IWidgetActionRes) => {
      const alert = {
        msg: <Trans>Widget archived</Trans>,
        action: () => restore(app, id),
      };
      actionsEffect(data);
      trackKey(TrackKey.WidgetArchive);
      return [data, alert] as [IWidgetActionRes, Alert];
    });

export const useWidgetArchive = () => {
  const app = useApiContext();
  const onArchive = useCallback(
    (id: number) => {
      return archive(app, id).then(([data, alert]) => {
        alertAction.success(alert);
      });
    },
    [app],
  );
  return useAsyncFnWithLoader(onArchive);
};

const restore = (
  app: ApiContextValue,
  id: number,
): Promise<[IWidgetActionRes, Alert]> =>
  app
    .service(serviceNames.widgetActions)
    .create({ id, type: WidgetApiAction.Restore })
    .then((data: IWidgetActionRes) => {
      const alert: Alert = {
        msg: <Trans>Widget restored</Trans>,
        action: () => archive(app, id),
      };
      actionsEffect(data);
      return [data, alert] as [IWidgetActionRes, Alert];
    });

export const useWidgetRestore = () => {
  const app = useApiContext();
  const onRestore = useCallback(
    (id: number) => {
      return restore(app, id).then(([data, alert]) => {
        alertAction.success(alert);
      });
    },
    [app],
  );
  return useAsyncFnWithLoader(onRestore);
};
