import { useEffect } from 'react';

import { feathersFind } from '@board/hooks/useFeathersFind';
import type { IExtBookmark } from '@board/shared/model';
import { serviceNames } from '@board/shared/serviceNames';

import { useExtBookmarkStore } from './store';

export const useBookmarksInit = () => {
  const setBookmarks = useExtBookmarkStore(s => s.set);
  useEffect(() => {
    feathersFind<IExtBookmark[], null>(serviceNames.extBookmarks, null).then(
      setBookmarks,
    );
  }, [setBookmarks]);
};
