export enum WidgetFavoriteKey {
  id = 'id',
  widgetId = 'widgetId',
  userId = 'userId',
  position = 'position',
  config = 'config',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

// from UI
export interface WidgetFavoriteDraft {
  widgetId: number;
}

// create method
export interface WidgetFavoriteCreate extends WidgetFavoriteDraft {
  userId: number;
}

export interface WidgetFavorite extends WidgetFavoriteCreate {
  id: number;
  createdAt: string;
  updatedAt: string;
  position: number;
  config: {
    icon: string | null;
    badge: boolean;
  };
}
export enum widgetFavoriteRelation {
  widget = 'widget',
}
