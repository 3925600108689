/* eslint-disable no-param-reassign */
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import type { SetRequired } from 'type-fest';
import { create } from 'zustand';

import { AlertType, alertAction } from '@board/basics/alert';
import {
  fnListSelector,
  memoKey,
  simpleMemo,
} from '@board/basics/helpers/selectors';
import { goTo } from '@board/basics/route';
import type { ApiContextValue } from '@board/basics/useApiContext';
import { DroppableTypes } from '@board/shared/dnd';
import { featherEventEmitters } from '@board/shared/featherTypes';
import type {
  AnyId,
  IBoard,
  IBoardUI,
  IShareFull,
  boardKeys,
} from '@board/shared/model';
import { serviceNames } from '@board/shared/serviceNames';
import { arrayHelper, shouldBeArr, shouldBeNum } from '@board/shared/utils';

import { devtools, immerMiddleware } from '../_shared_/zustand';
import { DialogKey, dialogAction } from '../dialogStore';
import { useUserStore, userSelector } from '../userStore';

type State = {
  boards: Record<string, IBoardUI>;
  current: number | null;
  list: number[];
  init: boolean;
  setBoards(payload: {
    boards?: IBoard | IBoard[] | IBoardUI | IBoardUI[];
    shared?: IShareFull[];
  }): void;
  update(
    payload: SetRequired<Partial<IBoardUI>, boardKeys.id>,
    opts?: { addW?: number; removeW?: number },
  ): void;
  updateMany(payload: Array<SetRequired<Partial<IBoard>, boardKeys.id>>): void;
  setCurrent(id: number | null): void;
  remove(id: number): void;
};

const setBoardInt = (
  state: State,
  board: IBoard | IBoardUI,
  user?: IShareFull['user'],
) => {
  if (state.boards[board.id]) {
    state.boards[board.id] = Object.assign(state.boards[board.id], board);
    if (user) {
      state.boards[board.id].user = user;
    }
  } else {
    state.boards[board.id] = {
      widgets: [],
      user,
      ...board,
    };
  }
};
export const useBoardStore = create<State>()(
  devtools(
    immerMiddleware(set => ({
      boards: {},
      list: [],
      current: null,
      init: false,
      setBoards: ({ boards, shared }) =>
        set(state => {
          if (boards) {
            shouldBeArr(boards).forEach(board => {
              setBoardInt(state, board);
            });
          }
          if (shared) {
            shared.forEach(({ board, user }) => {
              setBoardInt(state, board, user);
            });
          }
          state.list = Object.keys(state.boards).map(value =>
            Number.parseFloat(value),
          );

          state.init = true;
        }),
      update: (payload, options = {}) =>
        set(state => {
          // Got crashes from safari
          const board = state.boards[payload.id];
          if (board) {
            state.boards[payload.id] = Object.assign(board, payload);
            if (options.addW && !board.widgets.includes(options.addW)) {
              board.widgets.push(options.addW);
            }
            if (options.removeW) {
              board.widgets = board.widgets.filter(
                id => id !== options.removeW,
              );
            }
          }
        }),
      updateMany: boards =>
        set(state => {
          boards.forEach(board => {
            // Got crashes from safari
            if (state.boards[board.id]) {
              state.boards[board.id] = Object.assign(
                state.boards[board.id],
                board,
              );
            }
          });
        }),
      setCurrent: (id: number | null) =>
        set(state => {
          state.current = id;
        }),
      remove: boardId =>
        set(state => {
          state.list = state.list.filter(id => id !== boardId);
          delete state.boards[boardId];
        }),
    })),
    'boards',
  ),
);

const sortWithUser = (
  list: IBoardUI[],
): Array<{
  id: number;
  name: string;
  user: IBoardUI['user'];
  position: number;
}> => {
  const activeList = list.filter(i => !i.archived) as Array<IBoardUI<false>>;
  activeList.sort((a, b) => {
    if (a.user) return 1;
    if (b.user) return -1;
    return a.position - b.position;
  });
  return activeList.map(({ id, name, user, position }) => ({
    id,
    name,
    user,
    position,
  }));
};

export const boardSelector = {
  current: (state: State) => state.current,
  isInit: (state: State) => state.init,
  isBoardReady: (id?: AnyId | null) => (state: State) => {
    if (id != null) {
      const board = state.boards[shouldBeNum(id)];
      return Boolean(board?.init);
    }
    return false;
  },
  list: (state: State) =>
    fnListSelector(state.boards, {
      id: memoKey.boardList,
      list: state.list,
      fn: sortWithUser,
    }),
  ownedBoards: (state: State) =>
    simpleMemo(
      memoKey.ownedBoards,
      boardSelector.list(state).filter(i => !i.user),
    ),
  idList: (state: State) =>
    simpleMemo(
      memoKey.boardIdList,
      boardSelector.list(state).map(i => i.id),
    ),
  get: (id: number) => (state: State) => state.boards[id],
  getProp:
    <T extends keyof IBoardUI>(id: number, prop: T) =>
    (state: State) => {
      return boardSelector.get(id)(state)[prop];
    },
};

// -----ACTIONS----//
export const boardAction = {
  setCurrent: (id: number | null) =>
    unstable_batchedUpdates(() => {
      useBoardStore.getState().setCurrent(id);
    }),
  set: (payload: Parameters<State['setBoards']>[0]) =>
    unstable_batchedUpdates(() => {
      useBoardStore.getState().setBoards(payload);
    }),
  update: (
    payload: Parameters<State['update']>[0],
    opts?: Parameters<State['update']>[1],
  ) =>
    unstable_batchedUpdates(() => {
      useBoardStore.getState().update(payload, opts);
    }),
  notFoundRedirect: (id?: number) => {
    const list = boardSelector.idList(useBoardStore.getState());
    const prevId = arrayHelper.getPrev(list, id) || list[0];
    if (prevId && prevId !== id) {
      goTo.board(prevId);
    } else {
      dialogAction.show(DialogKey.BoardAdd);
    }
  },
  currentExistOrRedirect: () => {
    const state = useBoardStore.getState();
    if (state.current && !state.boards[state.current]) {
      boardAction.notFoundRedirect();
    }
  },
  remove: (id: number) => {
    const state = useBoardStore.getState();
    unstable_batchedUpdates(() => {
      state.remove(id);
    });
    if (boardSelector.current(state) === id) {
      boardAction.notFoundRedirect(id);
    }
  },
};

export const useCurrentBoardId = () => useBoardStore(state => state.current);
export const useCurrentBoard = () =>
  useBoardStore(state => {
    if (state.current == null) {
      return null;
    }
    return state.boards[state.current];
  });

// -----LISTENERS----//
export const boardsStoreSetupListeners = (app: ApiContextValue) => {
  const boardsService = app.service(serviceNames.boards);

  boardsService.on(featherEventEmitters.create, payload => {
    const board: IBoardUI = Object.assign(payload, {
      widgets: [],
      displayWidgets: [],
    });
    boardAction.set({ boards: board });
  });

  boardsService.on(featherEventEmitters.patch, payload => {
    boardAction.update(payload);
  });

  boardsService.on(featherEventEmitters.remove, payload => {
    boardAction.remove(payload.id);
  });

  app
    .service(serviceNames.itemsMove)
    .on(featherEventEmitters.create, payload => {
      if (payload.type === DroppableTypes.boards) {
        useBoardStore.getState().updateMany(payload.boards);
      }
    });

  const shareApi = app.service(serviceNames.share);
  if (shareApi) {
    shareApi.on(featherEventEmitters.remove, payload => {
      if (
        payload.email === userSelector.getProp('email')(useUserStore.getState())
      ) {
        const { name } = useBoardStore.getState().boards[payload.boardId];
        boardAction.remove(payload.boardId);

        alertAction.set(
          {
            msg: `Access to the board ${name} was withdrawn`,
          },
          AlertType.Info,
        );
      }
    });
  }
};
