import type React from 'react';
import { type PropsWithChildren, Suspense } from 'react';

import { SpinnerGridCenter } from '../Spinner';

export const LoadableComponent: React.FC<
  PropsWithChildren<{ fallback?: JSX.Element | null }>
> = ({ children, fallback }) => {
  return (
    <Suspense
      fallback={fallback === undefined ? <SpinnerGridCenter /> : fallback}
    >
      {children}
    </Suspense>
  );
};
