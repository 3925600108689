import type { FeatherQuery } from '../featherTypes';

import type { IItemWithWidgetAndBoard, ItemAny } from './item';
import type { IWidget, IWidgetWithBoard } from './widget';

export enum QSGroups {
  Boards,
  Navigation,
  Board,
  Widgets,
  Items,
  DocItems,
  ExtBookmarks,
  Actions,
  RecentlyUsed,
}

export enum QSFilter {
  Widgets,
  Items,
  Notes,
  Archived,
  ExtBookmark,
}

export interface QSQuery {
  query: {
    [QSGroups.Widgets]?: FeatherQuery<IWidget>;
    [QSGroups.Items]?: FeatherQuery<ItemAny>;
    [QSGroups.DocItems]?: FeatherQuery<ItemAny>;
  };
  limit?: number;
  archived?: boolean;
}

export type QSWidget = {
  type: QSGroups.Widgets;
  data: IWidgetWithBoard[];
};
type Item = {
  type: QSGroups.Items | QSGroups.DocItems;
  data: IItemWithWidgetAndBoard<ItemAny>[];
};

export type QSFind = QSWidget | Item;
export type QSFindRes = Array<QSFind>;
