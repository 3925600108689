/* eslint-disable no-param-reassign */
import { type Draft, produce } from 'immer';
import type { Reducer } from 'redux';

import type { IActionTypes } from '@board/shared/redux/types';

enum LoaderType {
  Success = 'success',
  Error = 'error',
  Pending = 'pending',
}

export type ILoader = {
  pending: boolean;
  success: boolean;
  error: boolean;
  payload?: {
    [key: string]: any;
  };
};
type TLoaderState = {
  [key: string]: {
    payload: any;
  } & ILoader;
};
const defaultState = {
  pending: false,
  success: false,
  error: false,
};

export const loaderDefault = defaultState;

type LoaderAction = {
  type: string;
  $loaderType: LoaderType;
  $orig: string;
  payload: { [key: string]: any };
};

export const loaderReducer: Reducer<TLoaderState, LoaderAction> = (
  state,
  action,
) => {
  return produce(state ?? {}, (draft: Draft<TLoaderState>) => {
    if (!action.$loaderType) {
      return undefined;
    }
    switch (action.$loaderType) {
      case LoaderType.Pending: {
        draft[action.$orig] = {
          ...defaultState,
          payload: action.payload,
          pending: true,
        };
        break;
      }
      case LoaderType.Success: {
        draft[action.$orig] = {
          ...defaultState,
          payload: action.payload,
          success: true,
        };
        break;
      }
      case LoaderType.Error: {
        draft[action.$orig] = {
          ...defaultState,
          payload: action.payload,
          error: true,
        };
        break;
      }
    }
    return undefined;
  });
};
export const loader = {
  success: (
    type: IActionTypes,
    payload: LoaderAction['payload'],
  ): LoaderAction => {
    return {
      type: `loader/${type.SUCCESS}`,
      $orig: type.ORIG,
      $loaderType: LoaderType.Success,
      payload,
    };
  },
  pending: (
    type: IActionTypes,
    payload: LoaderAction['payload'],
  ): LoaderAction => {
    return {
      type: `loader/${type.PENDING}`,
      $orig: type.ORIG,
      $loaderType: LoaderType.Pending,
      payload,
    };
  },
  error: (
    type: IActionTypes,
    payload: LoaderAction['payload'],
  ): LoaderAction => {
    return {
      type: `loader/${type.ERROR}`,
      $orig: type.ORIG,
      $loaderType: LoaderType.Error,
      payload,
    };
  },
};

type State = {
  loader: TLoaderState;
};

export enum loaderKey {
  boardArchive,
}

export const loaderSelector = {
  get:
    (action: { on: IActionTypes }) =>
    (state: State): ILoader => {
      const typeState = state.loader[action.on.ORIG];
      return typeState || defaultState;
    },
  byId:
    (action: { on: IActionTypes }, id: number) =>
    (state: State): ILoader => {
      const typeState = state.loader[action.on.ORIG];
      return typeState?.payload?.id === id ? typeState : defaultState;
    },
  byWidgetId:
    (action: { on: IActionTypes }, id: number | undefined) =>
    (state: State): ILoader => {
      if (!id) {
        return defaultState;
      }
      const typeState = state.loader[action.on.ORIG];
      return typeState?.payload?.widgetId === id ? typeState : defaultState;
    },
  byUniqueKey:
    (action: { on: IActionTypes }, key: loaderKey) =>
    (state: State): ILoader => {
      const typeState = state.loader[action.on.ORIG];
      return typeState?.payload?.key === key ? typeState : defaultState;
    },
  byKey:
    (action: { on: IActionTypes }, key: string, value: string | number) =>
    (state: State): ILoader => {
      const typeState = state.loader[action.on.ORIG];
      if (typeState?.payload?.[key] === value) {
        return typeState;
      }
      return defaultState;
    },
};
