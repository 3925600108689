import type { planKeys } from './user';

export enum trialKeys {
  id = 'id',
  type = 'type',
  expired = 'expired',
  paid = 'paid',
  createdAt = 'createdAt',
  userId = 'userId',
  notified = 'notified',
  period = 'period',
}

export enum TrialPeriods {
  EarlyBeta,
  Beta,
  Usual,
}

export const trialDays: { [key in TrialPeriods]: number } = {
  [TrialPeriods.Beta]: 30,
  [TrialPeriods.EarlyBeta]: 60,
  [TrialPeriods.Usual]: 15,
};

export interface ITrial {
  id: number;
  type: Exclude<planKeys, planKeys.free>;
  expired: boolean;
  userId: number;
  paid: boolean;
  notified: boolean;
  createdAt: string;
  period: TrialPeriods;
}
