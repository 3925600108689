import { useCallback, useMemo, useRef } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce, throttle } from 'lodash';

export const useDebouncedFn = (
  fn: (...args: any[]) => any,
  dependencies: any[],
  wait = 300,
) => {
  const debounced = debounce(fn, wait);

  return useCallback(debounced, dependencies);
};

export function useDebounce<T extends (...args: never[]) => void>(
  fn: T,
  ms: number,
  maxWait?: number,
) {
  const funcRef = useRef<T | null>(null);
  funcRef.current = fn;

  return useMemo(() => {
    const c = debounce(
      (...args: Parameters<T>) => {
        if (funcRef.current) {
          funcRef.current(...args);
        }
      },
      ms,
      { maxWait },
    );
    return c;
  }, [ms, maxWait]);
}

export function useThrottle<T extends (...args: never[]) => void>(
  fn: T,
  ms: number,
) {
  const funcRef = useRef<T | null>(null);
  funcRef.current = fn;

  return useMemo(
    () =>
      throttle(
        (...args: Parameters<T>) => {
          if (funcRef.current) {
            funcRef.current(...args);
          }
        },
        ms,
        {
          trailing: true,
        },
      ),
    [ms],
  );
}
