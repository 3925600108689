import type { Application } from '@feathersjs/feathers';

import type { ApiContextValue } from '../useApiContext';

export * from './logout';

let defineApi: ApiContextValue;
export const setApi = (api: Application<any> | ApiContextValue) => {
  defineApi = api as any as ApiContextValue;
};

export const getApi = () => defineApi;
export const getApiAsApp = () => defineApi as any as Application<any>;
