import type { IBoard, boardKeys } from './board';
import type { IItemGraph, ItemAny, ItemFull } from './item';

export enum widgetRelation {
  board = 'board',
  items = 'items',
}

export enum widgetModifier {
  NameId,
  NameIdType,
}

export enum widgetTypes {
  note = 'note',
  bookmarks = 'bookmark',
  tasks = 'tasks',
  extensionBookmarks = 'extension_bookmarks',
  documents = 'doc',
  files = 'FILES',
  embed = 'embed',
  excalidraw = 'draw',
  combo = 'combo',
}

export enum WidgetApiAction {
  Restore,
  Archive,
}

export const widgetTypesArr: widgetTypes[] = [
  widgetTypes.bookmarks,
  widgetTypes.documents,
  widgetTypes.tasks,
  widgetTypes.files,
  widgetTypes.note,
  widgetTypes.extensionBookmarks,
  widgetTypes.embed,
  widgetTypes.excalidraw,
  widgetTypes.combo,
];

export const widgetTypesArrNext = widgetTypesArr;
// export const widgetTypesArrNext = widgetTypesArr.concat(widgetTypes.documents);
export const widgetTypesName = widgetTypesArrNext.reduce(
  (acc, name) => {
    acc[name] = true;
    return acc;
  },
  {} as Record<widgetTypes, true>,
);

export const skipMeasureFor: { [key in widgetTypes]?: true } = {
  [widgetTypes.note]: true,
  [widgetTypes.embed]: true,
};

export enum widgetKeys {
  id = 'id',
  title = 'title',
  type = 'type',
  boardId = 'boardId',
  view = 'view',
  bg = 'bg',
  inPrivate = 'inPrivate',
  folderId = 'folderId',
  userId = 'userId',
  archived = 'archived',
  config = 'config',
  json = 'json',
}

export enum WidgetCreateType {
  Create = 'create',
  Insert = 'insert',
  NoLayout = 'no_layout',
}

export interface IWidgetDraft {
  title: string;
  type: widgetTypes;
  [widgetKeys.boardId]: number;
  [widgetKeys.userId]: number;
  config?: {
    icon?: string;
    collapsed?: boolean;
    child?: boolean;
  };
}

export type IWidgetCreateType =
  | {
      createType?: WidgetCreateType.Create;
    }
  | {
      createType: WidgetCreateType.Insert;
    }
  | {
      createType: WidgetCreateType.NoLayout;
      parentWidgetId: number;
    };
export type IWidgetCreate = IWidgetDraft & IWidgetCreateType;
export type IWidgetCreateUI = Omit<IWidgetDraft, widgetKeys.userId> &
  IWidgetCreateType;

export interface IWidget<JSON = Record<string, unknown>> extends IWidgetDraft {
  [widgetKeys.id]: number;
  bg: string;
  inPrivate: boolean;
  folderId: string;
  archived?: boolean | null;
  config: {
    icon?: string;
    collapsed?: boolean;
    child?: boolean;
  };
  json: JSON;
}
export type IWidgetCombo = IWidget<{ widgets?: number[]; columns?: number }>;

export type IWidgetAny = IWidgetCombo | IWidget;
export interface IWidgetWithBoard extends IWidget {
  board: {
    id: number;
    name: IBoard[boardKeys.name];
  };
}

export interface IWidgetWithFullBoard extends IWidget {
  board: Omit<IBoard, boardKeys.layouts>;
}

export type IWidgetFull = IWidget & Required<{ items: ItemFull[] }>;

export type IWidgetWith<T> = IWidget & { items: T[] };

export interface IWidgetCreateRes {
  widgets: IWidget[];
  board?: IBoard;
}

export type IWidgetRemoveRes = {
  widgets: Array<IWidget | IWidgetWith<ItemAny>>;
  updateWidget?: IWidget;
  board: IBoard<true> | IBoard;
};

export interface IWidgetInsertGraph
  extends Omit<IWidgetDraft, widgetKeys.boardId> {
  items?: Array<IItemGraph<ItemAny>>;
}

export interface IMoveWidget {
  id: number;
  boardId: number;
  toBoard: number;
}

export interface IMoveWidgetRes {
  widget: IWidget;
  fromBoard: IBoard;
  toBoard: IBoard;
}

export interface ICloneWidget {
  id: number;
  boardId: number;
  toBoard: number;
}
export interface ICloneWidgetRes {
  result: IWidget;
  board: IBoard;
}

export type IWidgetAction = {
  id: number;
  type: WidgetApiAction;
};

export type IWidgetActionRes =
  | {
      type: WidgetApiAction;
      widget: IWidget;
      board: IBoard;
    }
  | undefined;
