import { DialogKey } from '@board/dp/store/dialogStore/dialogKey';
import { ackeeAction } from '@board/shared/api/ackee';

const ackeeDialog: { [key in DialogKey]?: string } = {
  [DialogKey.BrowserBlockedOpenUrl]: 'bf8d42db-c727-4ada-855f-89a97531c6a5',
  [DialogKey.BoardEdit]: 'a9c7da85-d4c7-4d7a-ba47-b351bf4e1e15',
  [DialogKey.BoardsReorder]: '5d5de4cd-87b2-4dc6-818a-fa5267ff2dc0',
  [DialogKey.BoardAdd]: 'deee33f5-8eae-47ee-af2c-8ae3d469cda4',
  [DialogKey.CreateWidget]: '9ce8fe52-658c-438a-92a9-c855f234498f',
  [DialogKey.QuickSwitch]: 'ef2449e3-0eb1-418c-84bd-cde6f02f1486',
  [DialogKey.TasksAdd]: '599aff1f-731a-4db8-bd84-6b9742f817e9',
};

export const trackDialog = (key: keyof typeof ackeeDialog) => {
  const actionKey = ackeeDialog[key];
  if (actionKey) {
    ackeeAction(actionKey);
  }
};

export enum TrackKey {
  FloatWidget,
  FavWidget,
  UpgradePlan,
  WidgetArchive,
  WidgetDelete,
}
const ids: Record<TrackKey, string> = {
  [TrackKey.FloatWidget]: 'ac82103a-4074-4b0b-b802-db84d7137eb7',
  [TrackKey.FavWidget]: '2a8693da-56f5-43b7-9b7d-04b654be8ad2',
  [TrackKey.UpgradePlan]: '5c19fb0c-6903-471a-bb30-53e5dca249b7',
  [TrackKey.WidgetDelete]: '143c65b3-a537-4296-aa67-80ce25fa2f3f',
  [TrackKey.WidgetArchive]: '3d9fa0eb-4c63-4d60-92d7-fddf8e003575',
};
export const trackKey = (key: TrackKey) => ackeeAction(ids[key]);
