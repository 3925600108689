export enum NotAuthedType {
  RestorePwd,
  ChangePwd,
  AccountConfirm,
  GetPPP,
  UnsubscribePromo,
}

export type RestorePwd = {
  type: NotAuthedType.RestorePwd;
  email: string;
};
export type NotAuthedChangePwd = {
  type: NotAuthedType.ChangePwd;
  pwd: string;
  code?: string;
};
export type NotAuthedAccountConfirm = {
  type: NotAuthedType.AccountConfirm;
  code?: string;
};
export type NotAuthedUnsubscribe = {
  type: NotAuthedType.UnsubscribePromo;
  code?: string;
  value: boolean;
};

export type NotAuthedCreate =
  | RestorePwd
  | NotAuthedChangePwd
  | NotAuthedAccountConfirm
  | NotAuthedUnsubscribe
  | { type: NotAuthedType.GetPPP };
