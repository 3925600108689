export enum DialogKey {
  DayPlans,
  BoardAdd,
  BoardEdit,
  BoardsReorder,
  TasksAdd,
  CreateWidget,
  FileManager,
  Bookmarks,
  Help,
  QuickSwitch,
  WeekProgress,
  Pomodoro,
  MobileDrawer,
  UserMenu,
  BrowserBlockedOpenUrl,
}
