import type React from 'react';

import {
  Bookmark,
  BookmarkOne,
  DoneAll,
  GraphicStitching,
  GraphicStitchingFour,
  Notebook,
  Notes,
  Paperclip,
  SourceCode,
} from '@icon-park/react';
import type { Icon } from '@icon-park/react/es/runtime';

import type SvgIcon from '@mui/material/SvgIcon/SvgIcon';

import { widgetTypes } from '@board/shared/model/widget';

import type { WidgetConfig } from './types';

const widgetIcon: Record<widgetTypes, Icon> = {
  [widgetTypes.bookmarks]: Bookmark,
  [widgetTypes.documents]: Notebook,
  [widgetTypes.extensionBookmarks]: BookmarkOne,
  [widgetTypes.embed]: SourceCode,
  [widgetTypes.note]: Notes,
  [widgetTypes.files]: Paperclip,
  [widgetTypes.tasks]: DoneAll,
  [widgetTypes.excalidraw]: GraphicStitchingFour,
  [widgetTypes.combo]: GraphicStitching,
};
const basicWidget: Record<widgetTypes, [string, React.ReactElement]> = {
  [widgetTypes.bookmarks]: ['Bookmarks', <Bookmark key={'1'} />],
  [widgetTypes.documents]: ['Notes', <Notebook key={'12'} />],
  [widgetTypes.extensionBookmarks]: [
    'Extension bookmarks',
    <BookmarkOne key={'13'} />,
  ],
  [widgetTypes.embed]: ['Embed', <SourceCode key={'14'} />],
  [widgetTypes.note]: ['Note', <Notes key={'15'} />],
  [widgetTypes.files]: ['Files', <Paperclip key={'16'} />],
  [widgetTypes.tasks]: ['Checklist', <DoneAll key={'21'} />],
  [widgetTypes.excalidraw]: ['Excalidraw', <GraphicStitchingFour key={'31'} />],
  [widgetTypes.combo]: ['Collection', <GraphicStitching key={'41'} />],
};

export const widgetInfo = {
  getIcon: (type: widgetTypes): React.ReactElement => basicWidget[type][1],
  getIconComponent: (type: widgetTypes): Icon | typeof SvgIcon =>
    widgetIcon[type],
  getName: (type: widgetTypes): string => basicWidget[type][0],
  getTitle: ({ title, type }: { title?: string; type: widgetTypes }) =>
    title || widgetInfo.getName(type),
};

export const createWidget = <G,>(
  type: widgetTypes,
  config: Omit<WidgetConfig<G>, 'icon' | 'name'>,
): WidgetConfig<G> =>
  ({
    ...config,
    name: widgetInfo.getName(type),
    icon: widgetInfo.getIcon(type),
  }) as WidgetConfig<G>;
