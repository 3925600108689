import type { ReactNode } from 'react';

import type { FeathersError } from '@feathersjs/errors';
import type { HttpError } from 'http-errors';

export enum AlertType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
}

export type Alert = {
  key?: 'version';
  name?: ReactNode;
  replaceType?: AlertType;
  msg: ReactNode;
  action?(): // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
  void | Promise<[unknown, Alert]> | Promise<readonly [unknown, Alert]>;
  second?: {
    name: ReactNode;
    action(): void;
  };
};

export type RequestError = FeathersError | HttpError;
