const BOARD = 'b';
const SHARED = 'shared';
const REPORT = 'report';
const KANBAN = 'kanban';
const WIDGET = 'widget';
const DOC = 'doc';

export const LINKS = {
  BOARD,
  REPORT,
  KANBAN,
  WIDGET,
  SHARED,
  DOC,
};

export enum RouteDomain {
  Board = 'b',
  DocPage = 'doc',
  DocModal = 'note',
  TaskModal = 'task',
  SceneModal = 'scene',
  Widget = 'widget',
  Item = 'i',
}

export enum Routes {
  Board = '/b/:id',
  BoardDoc = '/note/:id',
  BoardWidget = '/b/:id/:widgetId/:itemId',
  Billing = '/profile/billing',
  ChangePwd = '/profile/change-password',
  Dashboard = '/dashboard',
  Demo = '/demo',
  DashboardFeedback = '/dashboard/feedback',
  Doc = '/doc/:id',
  ItemComments = '/i/c/:id',
  Transfer = '/transfer',
  Report = '/report',
  Profile = '/profile',
  Login = '/login',
  Pricing = '/pricing',
  Privacy = '/privacy',
  Notebook = '/notes',
  PlanWeek = '/planner/week',
  PlanWeekDate = '/planner/week/:date',
  PlanDay = '/planner/day',
  Terms = '/terms',
  TimelineToday = '/timeline',
  Timeline = '/timeline/:day',
  Task = '/task/:id',
  Feedback = '/feedback/:type/:code',
  Unsubscribe = '/unsubscribe/:code',
  Widget = '/widget/:id',
  Why = '/why',
  Changelog = '/changelog',
}

export const LoginRoutes = {
  auth: Routes.Login,
  signup: `${Routes.Login}/signup`,
  restorePassword: `${Routes.Login}/restore-password`,
  changePassword: `${Routes.Login}/change-password`,
  created: `${Routes.Login}/account-created`,
  restoreSent: `${Routes.Login}/restore-password-sent`,
  confirm: `${Routes.Login}/confirm`,
};

type Id = number | string;

export const getLink = {
  board: (id: Id) => `/${RouteDomain.Board}/${id}`,
  task: (id: Id) => `/${RouteDomain.TaskModal}/${id}`,
  widget: (id: Id) => `/${RouteDomain.Widget}/${id}`,
  docModal: (id: Id) => `/${RouteDomain.DocModal}/${id}`,
  doc: (id: Id) => `/${RouteDomain.DocPage}/${id}`,
  scene: (id: Id) => `/${RouteDomain.SceneModal}/${id}`,
  itemComments: (id: Id) => `/${RouteDomain.Item}/c/${id}`,
  feedback: (type: string, code: string) => {
    return `/feedback/${type}/${code}`;
  },
  unsubscribe: (code: string) => {
    return `/unsubscribe/${code}`;
  },
  dayPlan: (day: string) => `${Routes.PlanDay}/${day}`,
  profile: '/profile',
};

export const skipVersionFor = [getLink.doc('')];
