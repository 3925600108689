import { type Color, decomposeColor } from '@mui/material';
import * as allColors from '@mui/material/colors';

import { getKeys, isBoolean } from '../utils';

export const mapColors = (val: keyof Color): string[] =>
  getKeys(allColors)
    .map(color => {
      if (color !== 'common') {
        return allColors[color][val];
      }
      return null;
    })
    .filter(isBoolean);

export const defaultBgColor = '#F4F6FC';
export const defaultDarkColor = '#303030';

export const mainColors = {
  primary: '#4a69bb',
  secondary: '#e32249',
  background: {
    paper: '#fff',
    default: allColors.grey[50],
  },
};
export const mainColorsDark = {
  primary: '#6e87c8',
  secondary: '#e84e6d',
  background: {
    paper: allColors.grey[800],
    default: '#303030',
  },
};

export const boardThemeColors = [
  allColors.purple[800],
  allColors.purple[900],
  allColors.deepPurple[500],
  allColors.deepPurple[700],
  allColors.indigo[600],
  allColors.indigo[900],
  allColors.blue[800],
  allColors.lightBlue[700],
  allColors.cyan[800],
  allColors.teal[700],
  allColors.brown[700],
  allColors.brown[900],
  allColors.blueGrey[600],
  allColors.blueGrey[700],
  allColors.blueGrey[900],
  allColors.red[600],
  allColors.pink.A200,
  allColors.blueGrey[100],
  allColors.brown[100],
  allColors.amber[100],
  allColors.blue[100],
  allColors.purple[100],
  allColors.deepPurple[100],
  allColors.cyan[200],
  allColors.blue[200],
  allColors.purple[200],
  allColors.indigo[200],
];

export const toRgba = (color: number[], opacity: number) =>
  `rgba(${color.join(', ')}, ${opacity})`;

export const toRgbaString = (rgb: {
  r: number;
  g: number;
  b: number;
  a?: number;
}) => `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a || 1})`;

export const toRgbaObj = ([r, g, b, a]:
  | [number, number, number, number]
  | [number, number, number]) => ({
  r,
  g,
  b,
  a,
});

export const decomposeToRgba = (color: string, opacity: number) =>
  toRgba(decomposeColor(color).values, opacity);

export { decomposeColor };
