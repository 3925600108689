import { isDarkDefault } from '@board/app/utils/dom';
import { createStore } from '@board/dp';
import { LS, LSKeys } from '@board/shared/web';

type UseDarkMode = {
  isDark: boolean;
  toggle(): void;
};

export const useDarkMode = createStore<UseDarkMode>(set => ({
  isDark: isDarkDefault,
  toggle: () =>
    set(state => {
      LS.set({ [LSKeys.darkMode]: !state.isDark });
      return {
        isDark: !state.isDark,
      };
    }),
}));

export const useDarkSelector = {
  isDark: (store: UseDarkMode) => store.isDark,
};

export const useIsDark = () => useDarkMode(useDarkSelector.isDark);
