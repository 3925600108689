export enum ItemCommentKey {
  id = 'id',
  value = 'value',
  itemId = 'itemId',
  userId = 'userId',
}

export interface IItemCommentCreate {
  value: string;
  itemId: number;
}
export interface IItemCommentDraft extends IItemCommentCreate {
  userId: number;
}

export interface IItemComment extends IItemCommentDraft {
  id: number;
  createdAt: string;
  updatedAt: string;
}
