import React from 'react';

import { goTo } from '@board/basics/route';
import { type IErrorSystem, errorTypes } from '@board/shared/errorTypes';
import { Routes } from '@board/shared/links';
import { Trans } from '@board/ui/Lang';

import type { Alert } from './types';

export const systemMessage = (data: IErrorSystem, message: string): Alert => {
  switch (data.type) {
    case errorTypes.boardsLimit: {
      return {
        msg: (
          <Trans>
            You have reached the limit. (max: {data.max}, available:{' '}
            {data.available})
          </Trans>
        ),
        name: <Trans>Visit profile</Trans>,
        action: () => {
          goTo.route(Routes.Billing);
        },
      };
    }
    case errorTypes.planLimit: {
      return {
        msg: message,
        name: <Trans>Visit profile</Trans>,
        action: () => {
          goTo.route(Routes.Billing);
        },
      };
    }
    default:
      return {
        msg: <Trans>Unexpected error</Trans>,
      };
  }
};
