// eslint-disable-next-line import/no-extraneous-dependencies
import { FeathersError } from '@feathersjs/errors';
import { unstable_batchedUpdates } from 'react-dom';

import { onRequestError } from '@board/basics/alert';
import { getApi, getApiAsApp } from '@board/basics/api';
import {
  type IBoardWithWidgets,
  type IBoardWithWidgetsWithItems,
  type IShareFull,
  type IWidgetAny,
  boardRelation,
  shareEagers,
} from '@board/shared/model';
import { serviceNames } from '@board/shared/serviceNames';
import { isBoolean } from '@board/shared/utils';

import { boardAction, useBoardStore } from '../boardStore';
import { useItemStore } from '../itemsStore';
import { useWidgetStore, widgetAction } from '../widgetStore';

import type { Middleware } from './types';

export const getApiBoards = async (email: string) => {
  const shareApi = getApiAsApp().service(serviceNames.share);
  return Promise.all([
    getApi().service(serviceNames.boards).find({}),
    shareApi
      ? (shareApi.find({
          query: {
            email,
            $eager: shareEagers.all,
          },
        }) as IShareFull[])
      : [],
  ]);
};

const syncBoards: Middleware['syncBoards'] = async email => {
  const [boards, shared] = await getApiBoards(email);
  boardAction.set({ boards, shared });
};

const getBoardWidgetIds = (widgets?: IWidgetAny[]) => {
  return (widgets || [])
    .map(i => (i.archived || i.config?.child ? null : i.id))
    .filter(isBoolean);
};
const initCurrentBoardWithItems = (board: IBoardWithWidgetsWithItems) => {
  unstable_batchedUpdates(() => {
    const widgets = board.widgets || [];
    widgets.forEach(({ items, ...widget }) => {
      useItemStore.getState().init(widget.id, items);
      useWidgetStore.getState().set(widget);
    });

    useBoardStore.getState().setBoards({
      boards: {
        ...board,
        widgets: getBoardWidgetIds(widgets),
        init: true,
      },
    });
  });
};
export const initCurrentBoard = (board: IBoardWithWidgets) => {
  // [] used to fix the crash https://sentry.io/organizations/elvin-dzhavadov/issues/2799724630/
  const widgets = getBoardWidgetIds(board?.widgets);

  widgetAction.storeSet(board.widgets);
  boardAction.set({
    boards: { ...board, widgets, init: true },
  });
};

// TODO getApiAsApp
export const getApiCurrentBoard = async (
  boardId: number,
  attempts: number,
): Promise<IBoardWithWidgetsWithItems | undefined> => {
  return getApiAsApp()
    .service(serviceNames.boards)
    .get(boardId, {
      query: {
        $eager: boardRelation.widgetsWithItems,
      },
    })
    .then((board: IBoardWithWidgetsWithItems) => {
      return board;
    })
    .catch((e: Error) => {
      if (e instanceof FeathersError) {
        // timeout
        if (e.code === 408 && attempts <= 3) {
          return getApiCurrentBoard(boardId, attempts + 1);
        }
      }
      onRequestError(e);
      return undefined;
    });
};

const syncCurrentBoard: Middleware['syncCurrentBoard'] = async id => {
  const board = await getApiCurrentBoard(id, 1);
  if (board != null) {
    initCurrentBoardWithItems(board);
  }
};

export const middleware: Middleware = {
  syncBoards,
  syncCurrentBoard,
};
