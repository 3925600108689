import creator from 'keybuddy/creator';
import { isEditable, isFirefox } from 'keybuddy/helpers/browser';

export { DEFAULT_SCOPE, SPECIAL } from 'keybuddy/constants';

const filterFn = (e: KeyboardEvent) => {
  if (e) {
    // firefox not firing keyup for enter
    if (isFirefox && e.keyCode === 13) {
      return false;
    }
    const { target } = e;
    if (target instanceof HTMLElement) {
      return !isEditable(target) && target.getAttribute('role') !== 'menuitem';
    }
    return true;
  }
  return false;
};
export const keyHandlerEverywhere = creator(document, () => true);
export const keyHandler = creator(document, filterFn);
