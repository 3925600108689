import { AwsRegion } from '../../model/aws';
import { round } from '../math';

const MB = 1000 * 1000;
export const GB = MB * 1000;
export const mbToByte = (mbNeeded: number): number => mbNeeded * MB;
export const gbToByte = (mbNeeded: number) => mbNeeded * GB;
export const getMB = (bytes: number) => round(bytes / MB, 1);
export const getMBText = (bytes: number) => `${getMB(bytes)} mb`;

export const bucketByOffset = (timeOffset?: string): AwsRegion => {
  if (!timeOffset) {
    return AwsRegion.usa;
  }
  const offset = Number.parseFloat(timeOffset);
  if (Number.isNaN(offset)) {
    return AwsRegion.usa;
  }
  return offset < -2 ? AwsRegion.usa : AwsRegion.eu;
};
