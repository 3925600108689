export const getLocalPrice = (
  price: number,
  priceFactor: number,
  isAnnually: boolean,
) => {
  const fixedPrice = Math.max(
    Math.round(price * priceFactor),
    Math.ceil(price / 3),
  );
  return isAnnually ? fixedPrice : fixedPrice + 2;
};
