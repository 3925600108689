export enum serviceNames {
  admin = 'admin',
  boards = 'boards',
  boardsExport = 'boards-export',
  widgets = 'widgets',
  widgetsFavorite = 'widgets-favorite',
  widgetMove = 'widget-move',
  widgetClone = 'widget-clone',
  widgetActions = 'widget-actions',
  profile = 'profile',
  validateUrl = 'validate-url',
  importServices = 'import-services',
  importBoards = 'import-boards',
  extBookmarks = 'ext-bookmarks',
  share = 'share',
  subscribe = 'subscribe',
  trials = 'trials',
  quickSearch = 'quick-search',
  notification = 'notification',
  refreshToken = 'refresh-token',
  online = 'online',
  codeConfirm = 'code-confirm',
  attempts = 'attempts',
  notAuthed = 'not-authed',
  changePwd = 'change-pwd',
  timeline = 'timeline',
  feedback = 'feedback',
  feedbackMail = 'feedback-mail',
  items = 'items',
  itemExtras = 'item-extras',
  itemsMove = 'items-move',
  itemComments = 'item-comments',
  itemHistory = 'item-history',
  itemRevision = 'item-revision',
  userPref = 'user-pref',
  users = 'users',
  urlIcon = 'url-icon',
  prettify = 'prettify',
}
