export const test = null;

export enum LSKeys {
  backUrl = 'backUrl',
  darkMode = 'darkMode',
  showDrawer = 'showDrawer',
  reportRange = 'reportRange',
  showUnique = 'showUnique',
  token = 'boardist-token',
  plan = 'plan',
  feedback = 'feedback',
  lastVisited = 'last-visited',
  docModalFocus = 'b-doc-focus',
}

export const LS = {
  set: (data: { [key: string]: any }) =>
    Object.keys(data).forEach(key => {
      localStorage.setItem(key, JSON.stringify(data[key]));
    }),
  insert: (key: LSKeys, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  setStr: (key: LSKeys, value: string) => {
    localStorage.setItem(key, value);
  },
  remove: (keys: string[] | string) => {
    if (Array.isArray(keys)) {
      keys.forEach(key => {
        localStorage.removeItem(key);
      });
    } else {
      localStorage.removeItem(keys);
    }
  },
  get: (key: LSKeys) => {
    return LS.getAny(key);
  },
  getAny: (key: string) => {
    try {
      return JSON.parse(localStorage.getItem(key) as any);
    } catch (e) {
      // do nothing
    }
    return null;
  },
  string: (key: LSKeys) => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      // do nothing
      return '';
    }
  },
  has: (key: LSKeys) => Boolean(localStorage.getItem(key)),
  bool: (key: LSKeys) => Boolean(LS.get(key)),
  keys: LSKeys,
};
