import type { ILayouts } from '../layouts';

import type { ItemAny } from './item';
import type { IShareFull } from './share';
import type {
  IWidget,
  IWidgetFull,
  IWidgetInsertGraph,
  IWidgetWith,
} from './widget';

export enum boardKeys {
  id = 'id',
  name = 'name',
  layouts = 'layouts',
  userId = 'userId',
  bg = 'bg',
  freeMovement = 'freeMovement',
  position = 'position',
  archived = 'archived',
}

export interface IBoardCreate {
  [boardKeys.name]: string;
  [boardKeys.layouts]: ILayouts;
  [boardKeys.userId]: number;
  [boardKeys.bg]?: string | null;
}

export interface IBoardDraft extends IBoardCreate {
  [boardKeys.position]: number;
}

export interface IBoard<Archived extends boolean = false> extends IBoardCreate {
  [boardKeys.id]: number;
  [boardKeys.bg]: string | null;
  [boardKeys.freeMovement]: boolean;
  [boardKeys.archived]: Archived | boolean;
  [boardKeys.position]: Archived extends true ? null : number;
}

export interface IBoardUI<Archived extends boolean = false>
  extends IBoard<Archived> {
  user?: IShareFull['user'];
  widgets: number[];
  init?: boolean;
  filterQuery?: string;
}

export const isBoardUnArchived = (
  board: IBoard | IBoard<true>,
): board is IBoard => !board.archived;

export type IBoardWithoutLayout = Omit<IBoard, boardKeys.layouts>;

export interface IBoardWithWidgets extends IBoard {
  widgets: IWidget[];
}
export interface IBoardWithWidgetsWithItems extends IBoard {
  widgets: IWidgetWith<ItemAny>[];
}

export const boardRelation = {
  widgets: 'widgets',
  widgetsWithItems: 'widgets.items',
  fileWidgets: 'fileWidgets',
};

export interface IBoardInsertGraph extends IBoardDraft {
  widgets?: IWidgetInsertGraph[];
}

export enum boardModifier {
  NameId,
}

export interface IBoardExportRequest {
  ids: number[];
}
export type IBoardExportResponse = Array<
  IBoard & {
    widgets: IWidgetFull[];
  }
>;
