export enum AwsRegion {
  usa = 1,
  eu,
  test,
}

export enum AwsBucket {
  usa = 1,
  eu,
  test,
  usaDoc,
  euDoc,
}

export enum AwsType {
  File,
  Doc,
}

export const getAwsBucketEnum = (
  region: AwsRegion | undefined,
  type: AwsType,
): AwsBucket => {
  if (type === AwsType.File) {
    switch (region) {
      case AwsRegion.test:
        return AwsBucket.test;
      case AwsRegion.eu:
        return AwsBucket.eu;
      default:
        return AwsBucket.usa;
    }
  }
  switch (region) {
    case AwsRegion.test:
      return AwsBucket.test;
    case AwsRegion.eu:
      return AwsBucket.euDoc;
    default:
      return AwsBucket.usaDoc;
  }
};
