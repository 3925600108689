import { LS, LSKeys } from '@board/shared/web';
import { isCypress, isDarkOS } from '@board/shared/web/platform';

export const scrollToId = (id: string) => {
  try {
    const element = document.querySelector(id);
    element && element.scrollIntoView();
  } catch (e) {
    // do nothing
  }
};
export const removeDomElement = (query: string) => {
  const element = document.querySelector(query);
  if (element && element.parentNode) {
    element.parentNode.removeChild(element);
  }
};

export const scrollToWidget = (id: string) => {
  scrollToId(`#widget-${id}`);
};

export const transitionTimeout = isCypress ? { enter: 0, exit: 0 } : undefined;

export const isDarkDefault = LS.has(LSKeys.darkMode)
  ? LS.bool(LSKeys.darkMode)
  : isDarkOS;
