import { API_URL } from '../api';

export const redirect = (url: string) => {
  window.location.replace(url);
};
export const redirectLocal = (link: string) => redirect(`${API_URL}/${link}`);

function PopupCenter(url: string, name: string, w: number, h: number) {
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

  const left = width / 2 - w / 2 + window.screenLeft;
  const top = height / 2 - h / 2 + window.screenTop;
  const newWindow = window.open(
    url,
    name,
    `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`,
  );

  // Puts focus on the newWindow
  if (newWindow?.focus) {
    newWindow.focus();
  }
}

export const newWindow = (url: string) => {
  const newWindow = window.open(url);
  if (newWindow?.focus) {
    newWindow.focus();
  }
};
export const newWindowPopup = (url: string, name: string) => {
  PopupCenter(url, name, 700, 800);
};
export const newWindowPopupOrigin = (path: string, name: string) => {
  const url = `${window.location.origin}${path}`;
  PopupCenter(url, name, 700, 800);
};
export const newWindowOrigin = (path: string) => {
  const url = `${window.location.origin}${path}`;
  newWindow(url);
};
